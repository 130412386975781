<template>
  <v-dialog :value="value" max-width="750px" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Send an email</div>
      </v-card-title>
      <v-card-text v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </v-card-text>
      <v-card-text v-else>
        <v-layout class="mb-3">
          <v-autocomplete
            v-model="recipients"
            :items="voterList"
            :search-input.sync="search"
            label="Recipients"
            item-text="label"
            cache-items
            hide-details
            outline
            multiple
            dense
            chips
            deletable-chips
            return-object
          >
            <template v-slot:item="{ item }">
              <!-- Customize item rendering with checkbox -->
              <div class="d-flex align-center">
                <v-checkbox
                  :input-value="isSelected(item)"
                  :disabled="item.disabled"
                  color="primary"
                  hide-details
                  class="mr-2 mt-0"
                ></v-checkbox>
                <div>{{ item.label }}</div>
              </div>
            </template>
          </v-autocomplete>
        </v-layout>

        <v-layout class="mt-3">
          <v-select
            outline
            :items="noticeList"
            item-text="subject"
            item-value="key"
            label="Email type"
            placeholder="Choose an email type"
            hide-details
            dense
            v-model="noticeKey"
          />
        </v-layout>

        <div v-if="optionalMessageInput" class="mt-3">
          <p class="body-1">
            Include an optional message with the invitation email below:
          </p>
          <v-textarea
            box
            name="input-7-4"
            label=""
            placeholder="(Optional) Enter your message here"
            v-model="message"
            hide-details
          ></v-textarea>
        </div>

        <v-divider v-if="noticeHtml" class="mt-3"></v-divider>

        <v-layout v-if="noticeHtml" class="mt-3">
          <span class="body-1">Preview</span>
        </v-layout>

        <v-layout v-if="noticeHtml" class="mt-3">
          <iframe class="preview-html" :srcdoc="noticeHtml" scrolling="yes" />
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click.native="emitClose">close</v-btn>
        <v-spacer />
        <v-btn
          class="primary"
          :disabled="isDisabled"
          :loading="loading"
          @click.native="emitSend"
          >send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import checkScope from '@/lib/check-user-scopes';

import _ from 'lodash';

export default {
  name: 'SendMeetingInviteDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    voters: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    optionalMessageInput: {
      type: Boolean,
      default: false
    },
    notices: {
      type: Array,
      default() {
        return [];
      }
    },
    default: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('meetings', [
      'inviteTemplatePreview',
      'dialInInformationPreview'
    ]),
    ...mapGetters('meetings/notices', ['meetingNoticePreview']),
    ...mapGetters('meetings/voters', ['votersListData']),
    noticeList() {
      const invitation = {
        subject: 'Invite',
        key: 'invitation'
      };
      const troubleshootInvite = {
        subject: 'Troubleshooting - Invite Link',
        key: 'troubleshooting'
      };

      const emailDialInInformation = {
        subject: 'Dial-in Information',
        key: 'dialInInformation'
      };

      let type, virtualMeetingType, meetingType;

      if (this.currentMeeting && this.currentMeeting.options) {
        type =
          this.currentMeeting.options.virtualMode ||
          this.currentMeeting.options.subtype ||
          '';
        virtualMeetingType = this.currentMeeting.options.virtualMeetingType;
        meetingType = this.currentMeeting.options.type;
      }

      let list = [...this.notices];

      if (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.$route.params.shortcode,
          'meeting.units.voters.send-invite-email'
        ) ||
        meetingType === 'vbm'
      ) {
        list.push(invitation);
      }

      if (
        this.isAdmin &&
        (type === 'Virtual' || type === 'Hybrid') &&
        virtualMeetingType === 'GQ-Hosted' &&
        meetingType !== 'vbm'
      ) {
        list.push(troubleshootInvite);
      }

      list.push(emailDialInInformation);

      return list;
    },
    voterList() {
      let voters = [];

      if (
        this.currentMeeting &&
        this.currentMeeting.options &&
        this.currentMeeting.options.type === 'vbm'
      ) {
        voters = this.votersListData.map(v => {
          if (v.email !== 'noemail@getquorum.com') {
            return {
              ...v,
              label: `${v.name} (${v.email})`
            };
          } else {
            return {
              ...v,
              label: `${v.name} (no email on file)`,
              disabled: true
            };
          }
        });
      } else {
        voters = this.votersListData.map(v => {
          if (v.email !== 'noemail@getquorum.com') {
            return {
              ...v,
              label: `${v.name} (${v.email}) at ${v.unit} / ${v.address}`
            };
          } else {
            return {
              ...v,
              label: `${v.name} at ${v.unit} / ${v.address} (no email on file)`,
              disabled: true
            };
          }
        });
      }

      // Return initial list of voters, and current recipients as well
      // This ensures recipients who are not in the initial list of voters
      // will still appear in the autocomplete field
      return _.union(voters, this.recipients);
    },
    isDisabled() {
      return this.recipients.length === 0 || !this.noticeKey;
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      search: '',
      recipients: [],
      noticeKey: '',
      message: '',
      noticeHtml: ''
    };
  },
  watch: {
    search() {
      this.updateSearch();
    },
    voters() {
      if (
        this.currentMeeting &&
        this.currentMeeting.options &&
        this.currentMeeting.options.type === 'vbm'
      ) {
        this.recipients = this.voters.map(v => {
          return {
            ...v,
            label: `${v.name} (${v.email})`
          };
        });
      } else {
        this.recipients = this.voters.map(v => {
          return {
            ...v,
            label: `${v.name} (${v.email}) at ${v.unit} / ${v.address}`
          };
        });
      }

      this.noticeKey = '';
      this.noticeHtml = '';
    },
    noticeKey() {
      if (this.noticeKey) {
        this.getNoticeHtml();
      }
    },
    default() {
      if (this.default) {
        this.noticeKey = 'invitation';
      }
    }
  },
  methods: {
    ...mapActions('meetings', [
      'getInviteTemplatePreview',
      'getDialInTemplatePreview'
    ]),
    ...mapActions('meetings/notices', ['getMeetingsNoticePreview']),
    ...mapActions('meetings/voters', ['getMeetingVoters']),
    async getNoticeHtml() {
      if (this.noticeKey === 'invitation') {
        await this.getInviteTemplatePreview({
          shortCode: this.shortCode
        });
        this.noticeHtml = this.inviteTemplatePreview.content;
      } else if (this.noticeKey === 'troubleshooting') {
        await this.getInviteTemplatePreview({
          shortCode: this.shortCode,
          troubleshooting: true
        });
        this.noticeHtml = this.inviteTemplatePreview.content;
      } else if (this.noticeKey === 'dialInInformation') {
        await this.getDialInTemplatePreview({
          shortCode: this.shortCode
        });
        this.noticeHtml = this.dialInInformationPreview.content;
      } else {
        await this.getMeetingsNoticePreview({
          shortCode: this.shortCode,
          noticeKey: this.noticeKey,
          voterKey: ''
        });
        this.noticeHtml = this.meetingNoticePreview.content;
      }
    },
    updateSearch: _.debounce(async function() {
      await this.getMeetingVoters({
        shortCode: this.shortCode,
        search: this.search
      });
    }, 350),
    emitSend() {
      if (this.noticeKey === 'invitation') {
        this.$emit('invite', {
          recipients: this.recipients,
          message: this.message
        });
      } else if (this.noticeKey === 'troubleshooting') {
        this.$emit('invite', {
          recipients: this.recipients,
          message: this.message,
          troubleshooting: true
        });
      } else if (this.noticeKey === 'dialInInformation') {
        this.$emit('sendDial', {
          recipients: this.recipients
        });
      } else {
        this.$emit('send', {
          recipients: this.recipients,
          noticeKey: this.noticeKey
        });
      }

      // Reset message on send
      this.message = '';
      this.recipients = [];
      this.noticeKey = '';
      this.noticeHtml = '';
    },
    emitClose() {
      this.$emit('close');
    },
    isSelected(item) {
      // Check if item exists in recipients array
      return this.recipients.some(recipient => {
        const key = recipient.voterKey || recipient.key;
        return key === item.voterKey;
      });
    }
  }
};
</script>

<style scoped>
iframe {
  pointer-events: none;
}

.preview-html {
  width: 100%;
  height: 35vh;
  max-height: 700px;
}
</style>
