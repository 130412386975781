<template>
  <!-- Navigation Drawer
    Each v-if checks (checkRoute) if the list item needs to be rendered based on the already filtered route prop
    This is one way to enforce a specific order of the list items easily in HTML, without re-arranging the routes array
  -->

  <v-navigation-drawer
    app
    width="300px"
    clipped
    :value="value"
    @input="emitInput($event)"
  >
    <v-list>
      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingOverview') && !isVBM"
        :route-object="getRouteObject('meetingOverview')"
      />
      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingVbmOverview') && isVBM"
        :route-object="getRouteObject('meetingVbmOverview')"
      />

      <!-- Route contains "hidden" property, and is filtered out for all users -->
      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingTally')"
        :route-object="getRouteObject('meetingTally')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingBusiness')"
        :route-object="getRouteObject('meetingBusiness')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingUnits')"
        :route-object="getRouteObject('meetingUnits')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingProxies')"
        :route-object="getRouteObject('meetingProxies')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingNotices')"
        :route-object="getRouteObject('meetingNotices')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingNominations')"
        :route-object="getRouteObject('meetingNominations')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingWebcast')"
        :route-object="getRouteObject('meetingWebcast')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingFiles')"
        :route-object="getRouteObject('meetingFiles')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingReports')"
        :route-object="getRouteObject('meetingReports')"
      />

      <meeting-nav-drawer-list-tile
        v-if="checkRoute('meetingApprovals')"
        :route-object="getRouteObject('meetingApprovals')"
      />
    </v-list>

    <v-list v-if="!isVBM && !isTownhall && isExternalLinksAvailable">
      <!-- External Links section -->
      <v-subheader>External Links</v-subheader>
      <meeting-nav-drawer-list-tile-external
        label="Registration Desk"
        icon="table_restaurant"
        :url="registrationDeskUrl"
      />
    </v-list>

    <v-list v-if="isAdmin">
      <!-- Admin only section -->
      <v-subheader>Admin</v-subheader>
      <span v-for="(route, index) in adminNavRoutes" :key="`key-${index}`">
        <meeting-nav-drawer-list-tile
          v-if="showRoute(route)"
          :route-object="route"
          :count="checkCount(route)"
        />
      </span>

      <v-subheader>Admin - Customer Info</v-subheader>
      <meeting-nav-drawer-info
        v-if="currentAccount"
        :account-key="currentAccount.key"
        :subscription="currentAccount.subscription"
        :invoice-owing="totalInvoiceAmountOwing"
        :subscription-balance="subscriptionBalance"
        :loading="loading"
        @refresh="emitRefresh"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { startCase } from '@/filters';
import MeetingNavDrawerListTile from '@/components/MeetingNavDrawerListTile';
import MeetingNavDrawerListTileExternal from '@/components/MeetingNavDrawerListTileExternal';
import MeetingNavDrawerInfo from '@/components/MeetingNavDrawerInfo';

export default {
  name: 'MeetingNavDrawer',
  components: {
    MeetingNavDrawerListTile,
    MeetingNavDrawerListTileExternal,
    MeetingNavDrawerInfo
  },
  filters: {
    startCase
  },
  props: {
    value: {
      type: Boolean,
      default: null
    },
    routes: {
      type: Array,
      required: true
    },
    localProfile: {
      type: Object,
      default() {
        return {};
      }
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    },
    currentAccount: {
      type: Object,
      default() {
        return {};
      }
    },
    subscriptionBalance: {
      type: Number,
      default: 0
    },
    totalInvoiceAmountOwing: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    ...mapGetters('features', ['experimentalMode']),
    adminNavRoutes() {
      return this.routes.filter(route => route.isAdminNavCategory);
    },
    isVBM() {
      return this.currentMeeting.options?.type === 'vbm';
    },
    isTownhall() {
      return this.currentMeeting.options?.type_service === 'meeting_townhall';
    },
    // Link to external app
    registrationDeskUrl() {
      const shortcode = this.$route.params.shortcode;
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://regdesk.getquorum.com'
          : 'http://localhost:9000';
      return `${baseUrl}/meetings/${shortcode}/info`;
    },
    isExternalLinksAvailable() {
      const shortCode = this.currentMeeting.shortCode;
      if (
        this.isAdmin ||
        (shortCode &&
          this.scopes.campaigns[shortCode] &&
          this.scopes.campaigns[shortCode].actions &&
          this.scopes.campaigns[shortCode].actions.some(scope =>
            'read'.includes(scope)
          ))
      )
        return true;

      return false;
    }
  },
  methods: {
    emitInput(val) {
      this.$emit('input', val);
    },
    emitRefresh() {
      this.$emit('refresh');
    },
    showRoute(val) {
      if (val.isExperimental) {
        return this.experimentalMode;
      }
      return true;
    },
    checkRoute(routeName) {
      return this.routes.some(route => route.name === routeName);
    },
    getRouteObject(routeName) {
      return this.routes.find(route => route.name === routeName);
    },
    checkCount(route) {
      if (route.name === 'meetingServices') {
        return _.get(this.currentMeeting, 'options.additionalServices', [])
          .length;
      }
      return null;
    }
  }
};
</script>

<style scoped>
.v-list__tile__title {
  font-size: 14px !important;
}

.rounded-borders {
  border-radius: 10px;
}
</style>
