import http from './requestType';

/**
 * Returns a list of the units that belong to this proxy/meeting
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getMeetingsUnitsList({
  shortCode,
  searchText = '',
  page,
  size,
  descending,
  filters = '',
  filterType = '',
  customFields = []
}) {
  const params = {
    page,
    size,
    searchText,
    descending,
    customFields: JSON.stringify(customFields)
  };
  return http._get(
    `Meetings/${shortCode}/units?filters=${filters}&filterType=${filterType}`,
    params
  );
}

/**
 * Create a Unit with mailing address and contact emails
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unit - newly created unit data
 * @return {Promise} - Promise
 */
export function postMeetingsUnitsList(shortCode, unit) {
  return http._post(`Meetings/${shortCode}/units`, unit);
}

/**
 * get a list of units with no emails
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getUnitsWithNoEmails(shortCode) {
  return http._get(`Meetings/${shortCode}/units/actions/list-no-emails`);
}

/**
 * Get total units
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getTotalUnits(shortCode) {
  return http._get(`Meetings/${shortCode}/units/actions/get-total-units`);
}

/**
 * Get the unit associated with the id
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitId - the unit ID
 * @return {Promise} - Promise
 */
export function getMeetingsUnitsId(shortCode, unitId) {
  return http._get(`Meetings/${shortCode}/units/${unitId}`);
}

/**
 * Update a unit (cannot update voters)
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitId - the unit ID
 * @param  {object} unit - new unit data Object
 * @return {Promise} - Promise
 */
export function putMeetingsUnitsId(shortCode, unitId, unit) {
  return http._put(`Meetings/${shortCode}/units/${unitId}`, unit);
}

/**
 * Update multiple units (cannot update voters)
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitIdList - units Ids
 * @param  {object} unitsData - new units data Object
 * @return {Promise} - Promise
 */
export function putMeetingsMultipleUnitsId(shortCode, unitIdList, unitsData) {
  return http._put(`Meetings/${shortCode}/units`, {
    unitIdList,
    unitsData
  });
}

/**
 * Update control numbers for units (cannot update voters)
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitIdList - units Ids
 * @param  {number} controlNumberDigits - the number of control numbers
 * @return {Promise} - Promise
 */
export function putGenerateControlNumbers(
  shortCode,
  unitIdList,
  controlNumberDigits
) {
  return http._put(
    `Meetings/${shortCode}/units/actions/update-control-numbers`,
    {
      unitIdList,
      controlNumberDigits
    }
  );
}

/**
 * Export Voter Registration Cover Pages for specified units and send them by email.
 * @param  {String} shortCode  - the meeting shortCode
 * @param  {string} unitIdList - units Ids
 * @return {Promise}           - the results
 */
export function postSendVoterRegistrationPages(shortCode, unitIdList) {
  return http._post(
    `Meetings/${shortCode}/units/actions/export-registration-page`,
    {
      unitIdList
    }
  );
}

/**
 * Delete a unit
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitId - the unit ID
 * @return {Promise} - Promise
 */
export function deleteMeetingsUnitsId(shortCode, unitId) {
  return http._delete(`Meetings/${shortCode}/units/${unitId}`);
}

/**
 * Imports units into a meeting from a previosuly uploaded CSV or XLSX file.
 * @param  {string} shortCode                  - meeting shortcode
 * @param  {Object} file                       - csv/xslx file to be imported that's stored on S3
 * @param  {string} file.Bucket                - S3 Bucket for import file
 * @param  {string} file.Key                   - S3 Key for import file
 * @param  {Object} [covers]                   - covers file to be imported
 * @param  {string} covers.Bucket              - S3 Bucket for covers file
 * @param  {string} covers.Key                 - S3 Key for covers file
 * @param  {Object} [opts]                     - options for import
 * @param  {Object} opts.ignoreCustom          - if custom fields should be ignored (default false)
 * @param  {Object} opts.controlNumberDigits   - Number of digits if the control number will be randomly generated (default to 0)
 * @return {Promise} - Promise
 */
export function postImportUnits(
  shortCode,
  { Bucket, Key },
  covers,
  { ignoreCustom = false, controlNumberDigits = 0 } = {}
) {
  let payload = {
    Bucket,
    Key,
    options: { ignoreCustom, controlNumberDigits }
  };

  if (covers && covers.Bucket && covers.Key) {
    payload.covers = { Bucket: covers.Bucket, Key: covers.Key };
  }

  return http._post(
    `Meetings/${shortCode}/units/actions/import-units`,
    payload
  );
}

/**
 * Imports units into a meeting from a previous meeting
 * @param  {string} shortCode                - meeting shortcode
 * @param  {string} importShortCode          - (optional) source meeting shortcode to import from (priority)
 * @param  {boolean} importRecent            - (optional) copy from most recent meeting
 * @param  {string} importMeetingType        - (optional) specific meeting type to import
 * @param  {object} importCustomData         - (optional) specific unit customData to import
 * @return {Promise} - Promise
 */
export function postImportUnitsFromMeeting({
  shortCode,
  importShortCode = '',
  importRecent = false,
  importMeetingType,
  importCustomData = { filters: '', customFields: [] }
}) {
  const params = {
    importShortCode,
    importRecent,
    importMeetingType,
    importCustomData
  };
  return http._post(
    `Meetings/${shortCode}/units/actions/import-units-from-meeting`,
    params
  );
}

/**
 * Use this to delete multiple units in one request instead of using multiple DELETE operations.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitIds - the unitIds to delete
 * @return {Promise} - Promise
 */
export function deleteMeetingsMultipleUnitsIds(shortCode, unitIds) {
  return http._post(
    `Meetings/${shortCode}/units/actions/delete-units`,
    unitIds
  );
}

/**
 * Use this to delete all meeting units
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function deleteAllMeetingUnits(shortCode) {
  return http._post(`Meetings/${shortCode}/units/actions/delete-all-units`);
}

/**
 * Use this to reset attendance values for units.
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitIds - the unitIds to reset
 * @return {Promise} - Promise
 */
export function resetAttendanceUnitsIds(shortCode, unitIds) {
  return http._put(`Meetings/${shortCode}/units/actions/reset-attendance`, {
    unitIds
  });
}

/**
 * Payload containing target units and file to add
 *
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} requestBody - The Key/Bucket to the file to attach to this unit
 * @param  {string} mode - The upload mode. Merge mode requires a .docx file. Available values : normal, merge. Default value : normal.
 * @return {Promise} - Promise
 */
export function postMeetingUnitsFiles(shortCode, requestBody, mode) {
  return http._post(
    `Meetings/${shortCode}/units/actions/attach-files?mode=${mode}`,
    requestBody
  );
}

/**
 * Removes all attachments from the specified units.
 *
 * @param  {string} shortCode - the meeting shortcode
 * @param  {object} requestBody - contains object with unitKeys array to remove
 * @return {Promise} - Promise
 */
export function removeMeetingUnitsFiles(shortCode, requestBody) {
  return http._post(
    `Meetings/${shortCode}/units/actions/remove-files`,
    requestBody
  );
}

/**
 * Undo the assignment of shares for units
 * @param  {string} shortCode - the meeting shortcode
 * @param  {object} requestBody - contains property with array of selected and assigned unit numbers
 * @return {Promise} - Promise
 */
export function undoAssignShares(shortCode, requestBody) {
  return http._post(
    `Meetings/${shortCode}/units/actions/undo-assign-shares`,
    requestBody
  );
}

/**
 * Import units from a CSV file stored on S3
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} id - the unit ID
 * @return {Promise} - Promise
 */
export function postMeetingsUnitsCSVImport(shortCode) {
  return http._post(`Meetings/${shortCode}/units/import`);
}

/**
 * Get units custom data keys
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getCustomData(shortCode) {
  return http._get(`Meetings/${shortCode}/units/actions/custom-data`);
}

/**
 * Get units custom data keys
 * @param  {string} shortCode - the meeting shortcode
 * @return {Promise} - Promise
 */
export function getCustomDataKeys(shortCode) {
  return http._get(`Meetings/${shortCode}/units/actions/custom-data-keys`);
}

/**
 * Get unit changelogs
 * @param  {string} shortCode - the meeting shortcode
 * @param  {string} unitId - the unit ID
 * @param  {string} page - the page number
 * @param  {string} size - the page size
 * @param  {string} searchQuery - the search query
 * @param  {string} sort - the sort
 * @return {Promise} - Promise
 */
export function getUnitChangelogs({
  shortCode,
  unitId,
  page,
  size,
  searchQuery,
  sort
}) {
  return http._get(`Meetings/${shortCode}/units/${unitId}/changelogs`, {
    page,
    size,
    searchQuery,
    sort
  });
}
