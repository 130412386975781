import http from './requestType';

/**
 * Lists all the checklists
 * @param  {Object}  [params]             - query parameters
 * @param  {Object}  [params.meetingKey]  - filter checklists by meeting key
 * @return {Promise} - Promise
 */
export function getChecklists({ meetingKey } = {}) {
  return http._get(`Checklists/meeting/${meetingKey}`);
}

/**
 * Creates a new fulfilment checklist for a campaign. To get a list of checklist templates, query the GET /ChecklistTemplates route.
 * NOTE - each campaign may only have one checklist.*
 * @param {object} checklist - new checklist to create
 * @return {Promise} - Promise
 */
export function postChecklists(checklist) {
  return http._post('Checklists', checklist);
}

/**
 * Updates the information for a checklist. NOTE - it will ignore any checklist item updates. To update a checklist item, use the sub routes.
 * @param {object} checklist - new checklist payload
 * @return {Promise} - Promise
 */
export function patchChecklist(checklist) {
  return http._patch(`Checklists/${checklist.key}`, checklist);
}

/**
 * Sets the assignees for a checklist
 * @param {Object}    params            -
 * @param {String}    params.key        - checklist key
 * @param {String[]}  params.assignees  - array of assignee usernames
 * @return {Promise} - Promise
 */
export function putChecklistAssignees({ key, assignees }) {
  return http._put(`Checklists/${key}/assignees`, { assignees });
}

/**
 * Finds a checklist based on the supplied checklistKey
 * @param {string} key - The unique id for the checklist
 * @return {Promise} - Promise
 */
export function getChecklist(key) {
  return http._get(`Checklists/${key}`);
}

/**
 * Deletes a checklist and all associated items
 * @param  {string} key - The unique id for the checklist
 * @return {Promise} - Promise
 */
export function deleteChecklist(key) {
  return http._delete(`Checklists/${key}`);
}

/**
 * Get the list of possible checklist assignees
 * @return {Promise} - Promise
 */
export function getChecklistAssignees() {
  return http._get(`Checklists/assignees`);
}

/**
 * Get the list of possible checklist stages
 * @return {Promise} - Promise
 */
export function getChecklistStages() {
  return http._get(`Checklists/stages`);
}
