<template>
  <v-container fluid grid-list-lg>
    <v-alert
      v-if="!loadingStatuses.stats && !currentMeeting.stats"
      :value="true"
      class="ma-0 py-2"
      outline
      type="error"
      color="red accent-2"
      icon="error"
      dense
    >
      <strong>Some stats have failed to load</strong>
    </v-alert>
    <v-layout row wrap>
      <v-flex xs12 sm12 md8>
        <div class="gq-cell--section-title">
          Meeting Overview
        </div>
        <meeting-details-card
          :title="currentMeeting.name"
          :description="currentMeeting.description"
          :about="currentMeeting.meetingAbout"
          :location="currentMeeting.location"
          :meeting-date="currentMeeting.meetingDate"
          :meeting-timezone="currentMeeting.meetingTimezone"
          :expiry-date="currentMeeting.expiryDate"
          :rehearsal-date="currentMeeting.rehearsalAt"
          :rehearsal-duration="currentMeeting.rehearsalDuration"
          :nomination-expiry-date="currentMeeting.nominationExpiryDate"
          :corporation="currentMeeting.corporation"
          :contact="currentMeeting.contact"
          :addresses="currentMeeting.addresses"
          :documents="currentMeeting.documents"
          :meeting-type="currentMeeting.options.type"
          :cast-id="currentMeeting.castId"
          :cast-provider="currentMeeting.castProvider"
          :partner="currentMeeting.partner"
          :enable-voter-registration="
            currentMeeting.options.enableVoterRegistration
          "
        />
      </v-flex>
      <v-flex xs12 sm12 md4 column>
        <div class="gq-cell--section-title">
          Advance Voting Summary
        </div>
        <meeting-vote-summary
          :display-advance-toggle="currentMeeting.options.displayAdvanceToggle"
          :expiry-date="currentMeeting.expiryDate"
          :stats="currentMeeting.stats"
          :primary-stat="primaryStat"
          :display-secondary-stats="displaySecondaryStats"
          :disable-report="disableVoteCloseReport"
          :display-shares-as-percentages="
            currentMeeting.options.sharesAsPercentages
          "
          :display-voting-report="
            currentMeeting.options.displayVotingResultReport || false
          "
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
          :attendance-snapshot="currentMeeting.meetingMeta.attendanceSnapshot"
          :meeting-timezone="currentMeeting.meetingTimezone"
          :is-voting-close="isVotingClose"
          :collect-rtv-ballots="collectRtvBallots"
          :loading-stats="loadingStatuses.stats"
          @send="sendVoteClosedReport"
          @options="showReportOptionsDialog = true"
          @votingReport="downloadVotingReport"
        />

        <meeting-alerts
          v-if="checkAdmin"
          :stats="currentMeeting.stats"
          :account-data="account.data"
        />
      </v-flex>
    </v-layout>

    <!-- Meeting Stats Grid -->
    <meeting-stats-grid
      :current-meeting="currentMeeting"
      :loading-statuses="loadingStatuses"
      :can-export-consents="canExportConsents"
      @export="handleStatsExport"
      @run-report="handleStatsReport"
    />

    <!-- Display Proxy Holder summary if meeting has no business + proxy submissions (General Proxy Meeting) -->
    <div v-if="displayProxyHolderSummary" class="my-3">
      <proxy-holder-summary
        :proxy-holders="meetingProxyHoldersList"
        :options="currentMeeting.options"
        :timezone="currentMeeting.meetingTimezone"
      />
    </div>

    <!-- Displayed ONLY if you are an admin and combined vote tally data exists -->
    <div v-if="canSeeLiveVoteData && voteTallyCombinedInitV2.length > 0">
      <v-divider class="my-5" />

      <v-layout row class="mt-2 mb-2 ma-0">
        <v-flex xs6>
          <h3 class="mb-5">
            Voting Results
            <div class="voting-results-info pt-2">
              Click on the ELIGIBLE | INELIGIBLE button to toggle candidate
              eligibility

              <v-icon
                @click="showCandidateEligibilityDialog = true"
                class="pr-2"
                color="primary"
                small
                >info</v-icon
              >
            </div>
          </h3>
        </v-flex>
        <v-flex xs6>
          <div class="refresh">
            <span class="caption">
              <span v-if="isTallyLoading">Loading...</span>
              <span v-else>Updated {{ refreshTallyStatus }}</span>
            </span>
            <v-btn
              flat
              icon
              :loading="isTallyLoading"
              color="secondary"
              @click.native="refreshTallyV2"
            >
              <v-icon>cached</v-icon>
            </v-btn>
            <v-btn class="primary" @click.native="viewLiveVoteAnswers()" flat>
              VIEW VOTES
            </v-btn>
          </div>
        </v-flex>
      </v-layout>

      <vote-tally-combined-v2
        :questions="voteTallyCombinedInitV2"
        :meetingTimezone="currentMeeting.meetingTimezone"
        :display-rounded-results="currentMeeting.options.roundedResults"
        :rounded-results-precision="
          currentMeeting.options.roundedResultsPrecision
        "
        :collect-rtv-ballots="collectRtvBallots"
        @add-paper-option="addPaperOptionV2"
        @cancel-paper-option="cancelPaperOptionV2"
        @delete-paper-option="deletePaperOptionV2"
        @save-paper-changes="savePaperChangesV2"
        @cancel-paper-changes="cancelPaperChangesV2"
        @enable-save-paper-changes="enableSavePaperChangesV2"
        @view-write-in-vote="viewWriteInVote"
        @toggle-exclude-vote="toggleExcludeVote"
        @voting-stopped="refreshTallyV2"
        @toggle-winner="toggleWinnerVote"
      />
      <v-divider class="my-5" />
    </div>

    <h3 class="mb-5">
      {{
        collectRtvBallots ? 'Proxy Voting Results' : 'Advance Voting Results'
      }}
    </h3>
    <!-- Vote Tallies -->
    <v-alert
      v-if="noVoteData"
      color="blue"
      outline
      icon="warning"
      :value="true"
    >
      No meeting business available
    </v-alert>

    <vote-tally
      v-else
      :business="meetingBusinessData"
      :is-voting-close="isVotingClose"
      :enableVoteBreakdown="currentMeeting.options.enableVoteBreakdown"
      :enableVoteBreakdownDate="currentMeeting.options.enableVoteBreakdownDate"
    />

    <v-progress-circular
      v-if="loadingStatuses.stats"
      indeterminate
      color="primary"
      class="mt-3"
    />

    <!-- Displayed ONLY if you are an admin or collaborator and vote tally data exists -->
    <div v-else-if="!loadingStatuses.stats && currentMeeting.stats">
      <div
        v-if="
          voteTallyData.length > 0 && (canSeeLiveVoteData || collectRtvBallots)
        "
      >
        <v-divider class="my-5" />

        <v-layout row align-end class="mt-2 mb-2 ma-0">
          <h3>
            {{
              collectRtvBallots
                ? 'Ballot Voting Results'
                : 'Live Voting Results'
            }}
          </h3>
          <v-spacer />
          <v-btn
            v-if="canSeeLiveVoteData"
            class="primary"
            @click.native="viewLiveVoteAnswers()"
            flat
          >
            VIEW VOTES
          </v-btn>
        </v-layout>

        <live-vote-tally
          :questions="voteTallyData"
          :display-rounded-results="currentMeeting.options.roundedResults"
          :rounded-results-precision="
            currentMeeting.options.roundedResultsPrecision
          "
        />
      </div>
    </div>

    <!-- Combined tally v3 table
      Unlike the other tables, keep all the functions calls within the component
    -->
    <!-- Check admin status & if v3 display tally is toggled -->
    <!-- Temporary: Add canSeeLiveVoteData when no longer an experimental feature -->
    <div v-if="checkAdmin && displayTallyTablesV3">
      <v-divider class="my-3" />
      <v-expansion-panel>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>
              <strong>VOTING RESULTS V3</strong>
              <v-chip disabled small class="warning white--text"
                ><v-icon class="pr-1">visibility</v-icon>Experimental Feature:
                visible to admins only</v-chip
              >
            </div>
          </template>

          <v-container fluid>
            <v-layout row>
              <v-flex xs12>
                <div class="refresh">
                  <span class="caption">
                    <span v-if="isTallyLoading">Loading...</span>
                    <span v-else>Updated {{ refreshTallyStatus }}</span>
                  </span>
                  <v-btn
                    flat
                    icon
                    :loading="isTallyLoading"
                    color="secondary"
                    @click.native="refreshTallyV3"
                  >
                    <v-icon>cached</v-icon>
                  </v-btn>
                  <v-btn
                    class="primary"
                    @click.native="viewLiveVoteAnswers()"
                    flat
                  >
                    VIEW VOTES
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>

            <v-layout row v-if="displayVoteResultsToggle">
              <v-flex xs9></v-flex>
              <v-flex xs3>
                <v-card>
                  <v-card-title>View Voting Results by:</v-card-title>
                  <v-card-text>
                    <v-radio-group class="pt-0" v-model="v3Metric">
                      <v-radio
                        label="Unit Factors (SHARES)"
                        color="primary"
                        value="shares"
                      ></v-radio>
                      <v-radio
                        label="1:1 Vote Breakdown (UNITS)"
                        color="primary"
                        value="units"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>

            <vote-tally-combined-v3
              v-if="displayTallyTablesV3"
              :tally-data-v3="meetingRTVCombinedVoteV3ListData"
              :meeting-timezone="currentMeeting.meetingTimezone"
              :display-rounded-results="currentMeeting.options.roundedResults"
              :rounded-results-precision="
                currentMeeting.options.roundedResultsPrecision
              "
              :display-vote-results-toggle="displayVoteResultsToggle"
              :metric="v3Metric"
              @view-write-in-vote="viewWriteInVote"
              @voting-stopped="refreshTallyV3"
              @refresh-tally-v3="refreshTallyV3"
            />
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </div>

    <!-- Dialogs -->

    <export-files-dialog
      :is-open="showExportDialog"
      :processing="isExportProcessing"
      :error="exportDialogError"
      :email-to="recipientEmail"
      @close-dialog="closeExportDialog"
    />

    <report-options-dialog
      :is-open="showReportOptionsDialog"
      :contact-name="currentMeeting.contact.name"
      :contact-email="currentMeeting.contact.email"
      :is-secret="isSecret"
      :includeVoteInDownload="checkVoteBreakdown"
      @send="sendVoteClosedReport"
      @close="showReportOptionsDialog = false"
    />

    <voting-results-report-options-dialog
      :is-open="showVotingReportOptionsDialog"
      :contact-name="currentMeeting.contact.name"
      :contact-email="currentMeeting.contact.email"
      :is-secret="isSecret"
      @send="sendVotingResultsReport"
      @close="showVotingReportOptionsDialog = false"
    />

    <write-in-vote-dialog
      v-model="showWriteInVoteDialog"
      :proxy-list="writeInProxyList"
      @revoke-proxy="revokeProxy"
      @close="showWriteInVoteDialog = false"
    />

    <candidate-eligibility-toggle-dialog
      :is-open="showCandidateEligibilityDialog"
      @close="showCandidateEligibilityDialog = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ExportFilesDialog from '@/components/dialogs/ExportFilesDialog';
import MeetingDetailsCard from '@/components/MeetingDetailsCard';
import MeetingProxyStatCard from '@/components/MeetingProxyStatCard';
import StatCard from '@/components/StatCard';
import VoteTally from '@/components/VoteTally';
import VoteTallyCombinedV2 from '@/components/VoteTallyCombinedV2';
import VoteTallyCombinedV3 from '@/components/VoteTallyCombinedV3';
import LiveVoteTally from '@/components/LiveVoteTally';
import MeetingAlerts from '@/components/MeetingAlerts';
import MeetingVoteSummary from '@/components/MeetingVoteSummary';
import ReportOptionsDialog from '@/components/dialogs/ReportOptionsDialog';
import VotingResultsReportOptionsDialog from '@/components/dialogs/VotingResultsReportOptionsDialog';
import WriteInVoteDialog from '@/components/dialogs/WriteInVoteDialog';
import CandidateEligibilityToggleDialog from '@/components/dialogs/CandidateEligibilityToggleDialog';
import ProxyHolderSummary from '@/components/ProxyHolderSummaryTable';
import checkScope from '@/lib/check-user-scopes';
import MeetingStatsGrid from './overview/MeetingStatsGrid.vue';
import { dateFormatReadable } from '@/filters';

export default {
  name: 'MeetingOverview',
  components: {
    ExportFilesDialog,
    MeetingDetailsCard,
    MeetingProxyStatCard,
    MeetingStatsGrid,
    StatCard,
    VoteTally,
    VoteTallyCombinedV2,
    VoteTallyCombinedV3,
    LiveVoteTally,
    MeetingAlerts,
    MeetingVoteSummary,
    ReportOptionsDialog,
    WriteInVoteDialog,
    CandidateEligibilityToggleDialog,
    ProxyHolderSummary,
    VotingResultsReportOptionsDialog
  },
  filters: {
    dateFormatReadable
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    account: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      showReportOptionsDialog: false,
      showVotingReportOptionsDialog: false,
      showWriteInVoteDialog: false,
      showCandidateEligibilityDialog: false,
      recipient: {},
      isTallyLoading: false,
      refreshTallyStatus: moment().fromNow(),
      lastTallyRefresh: new Date(),
      v3Metric: 'shares'
    };
  },
  mounted() {
    // On setup, get the list of reports available for this user
    this.getMeetingReportList({ shortCode: this.shortCode });

    // Will get initial business data
    this.getMeetingBusiness({ shortCode: this.shortCode });

    // Retrieve the latest combined advanced + live vote tally
    if (this.canSeeLiveVoteData) {
      this.getMeetingRTVCombinedVoteV2Data({ shortCode: this.shortCode });

      if (this.displayTallyTablesV3) {
        this.getMeetingRTVCombinedVoteV3Data({ shortCode: this.shortCode });
      }

      let self = this;
      // Update the data age clock every minute
      setInterval(function() {
        self.updateRefreshStatus();
      }, 60000);
    }

    // Get the list of meeting proxy holders
    this.listMeetingProxyHolders({ shortCode: this.shortCode });
  },
  computed: {
    ...mapGetters(['login', 'isAdmin', 'scopes']),
    ...mapGetters('meetings/business', ['meetingBusinessData']),
    ...mapGetters('meetings/reports', [
      'showExportDialog',
      'isExportProcessing',
      'exportDialogError'
    ]),
    ...mapGetters('meetings/liveVotes', [
      'meetingRTVCombinedVoteV2ListData',
      'meetingRTVCombinedVoteV3ListData'
    ]),
    ...mapGetters('meetings/proxies', [
      'meetingWriteInProxyListData',
      'meetingProxyHoldersList'
    ]),
    ...mapGetters('meetings', ['loadingStatuses']),

    checkAdmin() {
      return this.isAdmin;
    },
    canSeeLiveVoteData() {
      // Any user with meeting.live-votes.read perms (Admin & Collaborators)
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.live-votes.read')
      );
    },
    canExportConsents() {
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.reports.consent.read')
      );
    },
    checkVoteBreakdown() {
      const options = this.currentMeeting.options;

      if (this.isAdmin) {
        return true;
      } else if (options.enableVoteBreakdown) {
        return moment(
          this.currentMeeting.options.enableVoteBreakdownDate
        ).isBefore();
      }
      return false;
    },
    canSeeSecretTallyData() {
      // Currently admins and collaborators (moderators/partners) can see the tally tables
      // Even when set as a secret question
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.elections.view.early')
      );
    },
    noVoteData() {
      return _.isEmpty(this.meetingBusinessData);
    },
    voteTallyData() {
      return this.currentMeeting.stats.votingTally;
    },
    voteTallyCombinedInitV2() {
      return this.prepareDataV2();
    },
    statsLoaded() {
      return this.currentMeeting.stats;
    },
    isVotingClose() {
      return (
        moment(this.currentMeeting.expiryDate).isBefore() ||
        moment(this.currentMeeting.meetingDate).isBefore()
      );
    },
    isSecret() {
      return _.find(this.meetingBusinessData, { isSecret: true }) &&
        !this.isVotingClose
        ? true
        : false;
    },
    disableVoteCloseReport() {
      const stats = this.currentMeeting.stats;
      const isAdminOrHasScope =
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.elections.view.early');

      if ((!isAdminOrHasScope && this.isSecret) || stats?.totalProxies <= 0) {
        return true;
      } else {
        return false;
      }
    },
    recipientEmail() {
      const profile = this.login.profile;
      return this.recipient.email || profile.email;
    },
    displaySecondaryStats() {
      return this.currentMeeting.options.displaySecondaryMetric;
    },
    displayCustomGroups() {
      return this.currentMeeting.options.displayCustomGroups;
    },
    primaryStat() {
      // Units is the primary attendance stat, by default
      if (
        (!this.displaySecondaryStats && !this.displayCustomGroups) ||
        !this.currentMeeting.options.primaryAttendanceMetric
      ) {
        return 'Units';
      } else {
        return this.currentMeeting.options.primaryAttendanceMetric;
      }
    },
    writeInProxyList() {
      return this.meetingWriteInProxyListData;
    },
    displayProxyHolderSummary() {
      // Display proxy holder summary if:
      // meeting has no business, and proxy submissions exist (General Proxy Meeting)
      return this.noVoteData && this.meetingProxyHoldersList.length > 0;
    },
    displayTallyTablesV3() {
      // Toggle v3 tally table visibility
      return this.currentMeeting.options?.displayTallyTablesV3;
    },
    displayVoteResultsToggle() {
      // Whether or not to show the Unit Factors <> 1:1 vote results toggle (v3 tally table)
      return this.currentMeeting.options?.displayVoteResultsToggle;
    },
    // The proxy template is the ontario specific on-bill-106
    isOnBill106Template() {
      return this.currentMeeting.options?.proxyTemplate === 'on-bill-106';
    },
    collectRtvBallots() {
      return this.currentMeeting.options?.collectRtvBallots;
    }
  },
  methods: {
    ...mapActions('meetings/business', ['getMeetingBusiness']),
    ...mapActions('meetings/reports', [
      'getMeetingReportList',
      'downloadReport',
      'exportReport',
      'openExportDialog',
      'closeExportDialog'
    ]),
    ...mapActions('meetings/liveVotes', [
      'getMeetingRTVCombinedVoteV2Data',
      'getMeetingRTVCombinedVoteV3Data',
      'toggleVoteExclusion',
      'toggleWinner'
    ]),
    ...mapActions('meetings/proxies', [
      'deleteMeetingPaperProxy',
      'saveMeetingPaperProxies',
      'listMeetingProxyWriteIns',
      'revokeMeetingWriteInProxy',
      'listMeetingProxyHolders'
    ]),
    // Handler methods
    handleStatsExport({ type, mode, filters, version }) {
      this.exportReport({
        shortCode: this.shortCode,
        type,
        mode,
        filters,
        version
      });
    },

    handleStatsReport(reportType) {
      this.downloadReport({
        shortCode: this.shortCode,
        reportType,
        $events: this.$events
      });
    },
    sendVoteClosedReport({
      type,
      mode,
      recipient,
      includeVoters,
      includeVote,
      exportAsBallot,
      includeVoteInDownload
    } = {}) {
      this.recipient = recipient;
      this.exportReport({
        shortCode: this.shortCode,
        type,
        mode,
        recipient: recipient,
        includeVoters,
        includeVote,
        exportAsBallot,
        includeVoteInDownload
      });
      this.showReportOptionsDialog = false;
    },
    sendVotingResultsReport({ type, mode, recipient } = {}) {
      this.recipient = recipient;
      this.exportReport({
        shortCode: this.shortCode,
        type,
        mode,
        recipient: recipient
      });
      this.showVotingReportOptionsDialog = false;
    },
    viewLiveVoteAnswers() {
      this.$router.push({
        name: 'meetingRTVLiveAnswers',
        params: {
          shortcode: this.$route.params.shortcode
        }
      });
    },
    updateRefreshStatus() {
      this.refreshTallyStatus = moment(this.lastTallyRefresh).fromNow();
    },
    async viewWriteInVote({ questionKey, vote }) {
      try {
        this.listMeetingProxyWriteIns({
          shortCode: this.shortCode,
          questionKey,
          vote
        });

        this.showWriteInVoteDialog = true;
      } catch {
        this.$events.$emit('toastEvent', 'Could not retrieve write-in proxies');
      }
    },
    async revokeProxy({ proxyKey }) {
      try {
        await this.revokeMeetingWriteInProxy({
          shortCode: this.shortCode,
          proxyKey
        });

        this.$events.$emit('toastEvent', 'Proxy Revoked');

        // Refresh the combined vote tally after proxy successfully revoked
        this.refreshTallyV2();

        // Refresh if v3 toggled
        if (this.displayTallyTablesV3) {
          this.refreshTallyV3();
        }
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Could not revoke proxy, please try again'
        );
      }
    },
    // ----- V2 related - Start ----- //
    async refreshTallyV2() {
      try {
        this.isTallyLoading = true;
        await this.getMeetingRTVCombinedVoteV2Data({
          shortCode: this.shortCode
        });

        // Set the latest refresh and update the refresh status
        this.lastTallyRefresh = new Date();
        this.updateRefreshStatus();
      } catch (err) {
        throw err;
      } finally {
        this.isTallyLoading = false;
      }
    },
    prepareDataV2() {
      // 1) Calculate the total votes from what has been stored
      // 2) Retain the saved paper vote tally vs what's been edited/unsaved
      // 3) Edits won't update the total votes column (live+adv+paper votes)
      // 4) Handle special paper vote values (blanks are zeros)
      return _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        // Keep a flag for each question that could potentially be edited
        // Determines whether the save button is activated for the question
        if (question.canSave === undefined) {
          question.canSave = false;
        }

        _.map(question.tally, answer => {
          // Populate the editable paper vote text field
          // For vote values of 0, format to empty
          if (answer.numVotesPaperEdit === undefined) {
            answer.numVotesPaperEdit =
              answer.numVotesPaper === 0 ? '' : answer.numVotesPaper;
          }

          // Excluded votes are shifted to the bottom of the tables
          if (answer.isExcluded) {
            answer.numVotesTotal = '-';
            return answer;
          }

          // Keep track of the total number of votes from stored values
          answer.numVotesTotal =
            answer.numVotes +
            answer.numVotesAdv +
            (!answer.numVotesPaper ? 0 : parseFloat(answer.numVotesPaper));

          return answer;
        });
        return question;
      });
    },
    addPaperOptionV2(questionId) {
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        if (question.id === questionId) {
          let tallyWithNewLine = question.tally;
          const blankOption = {
            newOptionKey: `new-${tallyWithNewLine.length}`,
            vote: '',
            numVotesPaperEdit: null
          };
          tallyWithNewLine.push(blankOption);
          question.tally = tallyWithNewLine;
        }
        return question;
      });
    },
    cancelPaperOptionV2({ questionId, optionKey }) {
      // Find and remove the target option passed
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        if (question.id === questionId) {
          // Clone the question tally so we can operate on it
          const filterTally = _.cloneDeep(question.tally);
          _.remove(filterTally, {
            newOptionKey: optionKey
          });
          question.tally = filterTally;
          this.$events.$emit('toastEvent', 'Option removed');
        }
        return question;
      });
    },
    // Delete a saved paper option - applied on the advance question reference
    async deletePaperOptionV2({ questionKey, vote, liveQuestionKey }) {
      try {
        await this.deleteMeetingPaperProxy({
          shortCode: this.shortCode,
          questionKey,
          vote,
          liveQuestionKey
        });
        this.$events.$emit('toastEvent', 'Paper proxy removed');
        //this.$forceUpdate();
        this.refreshTallyV2();
      } catch {
        this.$events.$emit('toastEvent', 'Could not remove paper vote entry');
      }
    },
    async savePaperChangesV2(questionId) {
      const saveQuestion = _.find(this.meetingRTVCombinedVoteV2ListData, {
        id: questionId
      });
      // Save the changes - applied on the advance question reference
      try {
        await this.saveMeetingPaperProxies({
          shortCode: this.shortCode,
          questionKey: saveQuestion.question_key,
          paperProxies: saveQuestion.tally,
          liveQuestionKey: saveQuestion.live_question_key
        });
        this.$events.$emit('toastEvent', 'Changes saved');
        this.refreshTallyV2();
      } catch {
        this.$events.$emit(
          'toastEvent',
          'Changes could not be save, invalid input found'
        );
      }
    },
    async cancelPaperChangesV2(questionId) {
      // Find and reset question
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        // Find the target question
        if (question.id === questionId) {
          // Reset and remove unsaved new line items
          question.tally = _.filter(question.tally, 'answerId');

          // Reset the edit vote total
          _.map(question.tally, vote => {
            vote.numVotesPaperEdit =
              vote.numVotesPaper === 0 ? '' : vote.numVotesPaper;
            return vote;
          });
          question.canSave = false;
        }
        return question;
      });
      this.$events.$emit('toastEvent', 'Changes have been reset');
    },
    async enableSavePaperChangesV2(questionId) {
      // Enable the save button for the target question
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        // Find the target question
        if (question.id === questionId) {
          question.canSave = true;
        }
        return question;
      });
    },
    async toggleExcludeVote({ questionKey, exclusion, liveQuestionKey }) {
      try {
        await this.toggleVoteExclusion({
          shortCode: this.shortCode,
          questionKey,
          exclusion,
          liveQuestionKey
        });

        // Refresh the combined vote tally after successfull proxy revokes
        this.refreshTallyV2();

        // Refresh Advance voting results
        this.getMeetingBusiness({ shortCode: this.shortCode });
      } catch (err) {
        const message =
          err.response.data || 'Could not toggle exclusion, please try again';
        this.$events.$emit('toastEvent', message);
      }
    },

    async toggleWinnerVote({ questionKey, liveQuestionKey, winner }) {
      try {
        await this.toggleWinner({
          shortCode: this.shortCode,
          questionKey,
          winner,
          liveQuestionKey
        });

        // Refresh the combined vote tally after successfull proxy revokes
        this.refreshTallyV2();

        // Refresh Advance voting results
        this.getMeetingBusiness({ shortCode: this.shortCode });
      } catch (err) {
        const message = err.response.data || 'Could not toggle winner';
        this.$events.$emit('toastEvent', message);
      }
    },
    // ----- V2 related - End ----- //

    // ----- V3 related - Start ----- //
    // NOTE: Most V3 functions are contained within the <voteTallyCombinedV3 /> component
    async refreshTallyV3() {
      try {
        this.isTallyLoading = true;
        await this.getMeetingRTVCombinedVoteV3Data({
          shortCode: this.shortCode
        });

        // Set the latest refresh and update the refresh status
        this.lastTallyRefresh = new Date();
        this.updateRefreshStatus();
      } catch (err) {
        throw err;
      } finally {
        this.isTallyLoading = false;
      }
    },

    // ----- Reporting functions ----- //
    downloadVotingReport() {
      this.showVotingReportOptionsDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';

.gq-cell--section-title.overview {
  position: relative;
  left: 15px;
  top: 16px;
}
.refresh {
  text-align: right;
}
.voting-results-info {
  font-size: 13px;
  font-weight: 400;
}
.v-card__title,
.v-card__text {
  padding-bottom: 0;
}
.v-card__text {
  padding-top: 0;
}
</style>
