<!-- Header component is the toolbar portion of the layout.
In Vuetify, the navigtation drawer is part of the toolbar, therefore mapping/filtering the relevant routes
is included here as well in the <meeting-nav-drawer> component
-->

<template>
  <div class="gq-header">
    <!-- Receive dialog and toast events -->
    <global-events />

    <!-- Header theme -->
    <v-toolbar
      :app="sideNavRequired"
      dark
      class="gq-toolbar primary"
      :extended="sideNavRequired"
      clipped-left
      clipped-right
      elevation-1
    >
      <template v-slot:extension v-if="sideNavRequired">
        <v-toolbar-side-icon
          dark
          @click.stop="drawer = !drawer"
          v-if="sideNavRequired"
        />
        <v-toolbar-title class="ml-3">
          {{ meetingNavTitle }}
        </v-toolbar-title>

        <status-button v-if="isAdmin && sideNavRequired" />

        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="showOverdueInvoicesIndicator"
              class="mx-2"
              color="red"
              v-bind="attrs"
              v-on="on"
              >Overdue invoices</v-chip
            ></template
          >
          <span v-html="overdueReasons"></span>
        </v-tooltip>

        <span
          v-if="currentNotes && showMeetingNotes"
          class="mr-2 cursor-pointer"
          @click="expandNotesSideBar(!notesDrawer)"
        >
          Notes</span
        >
        <!-- Meeting notes and info dialog section -->
        <span
          class="cursor-pointer"
          v-if="currentNotes && showMeetingNotes"
          @click="expandNotesSideBar(!notesDrawer)"
          >({{ totalNoteCount }})</span
        >

        <!-- Show notes action with unread indicator only for collaborator  users -->
        <v-badge v-if="showMeetingNotes && !isAdmin" right overlap color="red">
          <template v-slot:badge>
            <span v-if="unreadNotes.length > 0">
              {{ unreadNotes.length }}
            </span>
          </template>
          <v-btn
            class="ml-0"
            flat
            icon
            @click="expandNotesSideBar(!notesDrawer)"
          >
            <v-icon>comment</v-icon>
          </v-btn>
        </v-badge>

        <!-- Show notes action without unread indicator for admin users -->
        <v-btn
          v-if="isAdmin && sideNavRequired && !isVBM"
          class="ml-0"
          flat
          icon
          @click="expandNotesSideBar(!notesDrawer)"
        >
          <v-icon>comment</v-icon>
        </v-btn>

        <v-btn
          v-if="isAdmin && sideNavRequired && !isVBM"
          flat
          icon
          @click="showQuickInfoDialog = true"
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-btn
          v-if="enableChatWidget"
          flat
          icon
          @click.native="displayHelpscout"
        >
          <v-icon>chat_bubble</v-icon>
        </v-btn>
      </template>
      <v-toolbar-title> <slot name="top-left-action" /> </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-layout align-center v-if="canSeeSchedulingWizardButton">
          <!-- Scheduling Wizard Button -->
          <v-tooltip bottom>
            <v-btn
              color="#E4853A"
              class="pa-2"
              slot="activator"
              @click.native="goToMeetingWizard()"
            >
              <v-icon left>support_agent</v-icon>
              Book a service
            </v-btn>
            <span>Scheduling Wizard - experimental feature</span>
          </v-tooltip>
        </v-layout>
        <apps-menu />
      </v-toolbar-items>
      <v-menu
        :nudge-width="300"
        offset-x
        offset-y
        :close-on-content-click="false"
      >
        <v-btn fab dark small slot="activator" class="primary">
          <v-icon>person</v-icon>
        </v-btn>
        <header-profile-card
          :name="login.profile.name"
          :email="login.profile.email"
          :is-admin="isAdmin"
          @change-password="navigateToChangePassword"
          @logout="logout"
        />
      </v-menu>
    </v-toolbar>

    <!-- Meeting left drawer navigation -->
    <meeting-nav-drawer
      v-if="sideNavRequired"
      v-model="drawer"
      :current-meeting="currentMeeting"
      :current-account="currentMeetingAccount"
      :subscription-balance="subscriptionBalance"
      :total-invoice-amount-owing="totalInvoiceAmountOwing"
      :routes="routes"
      :local-profile="localProfile"
      :loading="loading"
      @refresh="refreshSubscription"
    />

    <!-- Meeting notes right drawer navigation -->
    <!-- Open notes drawer if:
      - There are unread notes
      - Is not an admin
      - Is not a vbm type meeting
    -->
    <meeting-notes-drawer
      v-if="currentMeeting && showMeetingNotes"
      v-model="notesDrawer"
      :notes-list="currentNotes"
      :agent-notes-list="currentAgentNotes"
      :account-notes-list="accountNotes"
      :short-code="$route.params.shortcode"
      :meeting-key="currentMeeting.key"
      :meeting-timezone="currentMeeting.meetingTimezone"
      :unread-notes="unreadNotes"
      :current-meeting-account="currentMeetingAccount"
      :current-meeting="currentMeeting"
      :checklists="meetingChecklistData"
      @close="expandNotesSideBar(!notesDrawer)"
    />

    <!-- Consider moving the notes dialogs and related code to the meeting main -->
    <!-- The toolbar activates the notes drawer, save that state to store -->

    <!-- Toolbar drawers and dialogs -->
    <meeting-quick-info-dialog
      v-if="isAdmin && currentMeeting"
      :is-open="showQuickInfoDialog"
      :meeting="currentMeeting"
      :account="currentMeetingAccount"
      :stats="currentMeeting.stats"
      :checklists="meetingChecklistData"
      @close="showQuickInfoDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import GlobalEvents from '@/components/GlobalEvents';
import MeetingNavDrawer from './MeetingNavDrawer';
import MeetingNotesDrawer from './MeetingNotesDrawer';
import { mapMeetingRoutes, filterRoutes } from '@/lib/map-meeting-routes';
import meetingRoutes from '@/routes/meetings';

import MeetingQuickInfoDialog from '@/components/dialogs/MeetingQuickInfoDialog';
import StatusButton from '@/components/StatusButton';

import HeaderProfileCard from '@/components/HeaderProfileCard';
import AppsMenu from '@/components/AppsMenu';

export default {
  name: 'Header',
  components: {
    GlobalEvents,
    MeetingNavDrawer,
    MeetingNotesDrawer,
    MeetingQuickInfoDialog,
    HeaderProfileCard,
    StatusButton,
    AppsMenu
  },
  data() {
    return {
      drawer: null,
      notesDrawer: null,
      showMeetingNotesDialog: false,
      showQuickInfoDialog: false,
      indicator: false,
      statusOptions: {},
      loading: false,
      initialFetch: true
    };
  },
  watch: {
    async currentMeeting() {
      try {
        // Hide the side nav drawer by default if meeting
        // is a VBM and user is non-admin
        if (this.currentMeeting && !this.isAdmin) {
          this.drawer = this.isVBM ? false : true;
        }

        // This is to reduce checklists api calls
        if (this.currentMeeting && this.isAdmin && this.initialFetch) {
          this.initialFetch = false;

          await this.getChecklists(this.currentMeeting.key);

          await this.getAccountSubscription({
            key: this.currentMeeting.accountKey,
            clearCache: false
          });
        }
      } catch (err) {
        console.error(err);
        if (err.response.status === 429) {
          this.$events.$emit('toastEvent', 'Rate limit for Checklists reached');
        }
      }
    },
    currentMeetingShortCode(newValue, oldValue) {
      if (oldValue && !newValue && !this.currentMeeting) {
        // change from one meeting to another
        // refetch another checklists and account subscription
        this.initialFetch = true;
      }

      this.setNotesDrawer();
    },
    notesDrawer(value) {
      this.chatPosition({
        shortCode: this.$route.params.shortcode,
        drawerState: value
      });
    },
    enableChatWidget: {
      handler(value) {
        if (value) {
          // Ensures we try loading chat after a hard reload
          this.loadChat();
        } else {
          // Destroy the chat is the option is false
          this.chatDestroy();
        }
      }
    }
  },

  computed: {
    ...mapGetters(['login', 'isAdmin', 'scopes', 'getScopeByAction']),
    ...mapGetters('meetings', ['meetingByShortCode', 'meetingListData']),
    ...mapGetters('meetings/checklists', ['meetingChecklistData']),
    ...mapGetters('meetings/notes', [
      'notesByShortCode',
      'notesDrawerState',
      'agentNotesByShortCode'
    ]),
    ...mapGetters('accounts', [
      'accountList',
      'accountListData',
      'accountNotesByKey',
      'subscription'
    ]),
    ...mapGetters('invoices', ['outstandingAccountInvoices']),

    currentMeeting() {
      return this.meetingByShortCode(this.$route.params.shortcode);
    },
    currentMeetingAccount() {
      return _.get(this.currentMeeting, 'accountKey')
        ? this.accountList[this.currentMeeting.accountKey]
        : null;
    },
    currentMeetingShortCode() {
      return this.$route.params.shortcode;
    },
    routes() {
      // If currentMeeting not loaded or meetingRoutes not available
      if (!this.currentMeeting || !meetingRoutes) {
        return [];
      }

      // 1. map the meeting routes
      const mappedRoutes = mapMeetingRoutes({
        routes: meetingRoutes
      });

      // 2. filter the routes based on scopes, admin status, meeting options
      let filteredRoutes = filterRoutes({
        routes: mappedRoutes,
        shortCode: this.$route.params.shortcode,
        scopes: this.scopes,
        isAdmin: this.isAdmin
      });

      // only keep VBM Overview page, webcast page, and admin-only access
      // pages if a vbm meeting
      if (this.currentMeeting.options.type === 'vbm') {
        return filteredRoutes.filter(
          route =>
            route.adminOnly ||
            route.name === 'meetingVbmOverview' ||
            route.name === 'meetingWebcast'
        );
      }

      // Filter out meetingWebcast if not a virtual meeting OR not a 'meeting' type
      if (
        this.currentMeeting.options.type !== 'meeting' ||
        (this.currentMeeting.options.subtype &&
          this.currentMeeting.options.subtype !== 'Virtual')
      ) {
        filteredRoutes = filteredRoutes.filter(
          route => route.name !== 'meetingWebcast'
        );
      }

      return filteredRoutes;
    },
    sideNavRequired() {
      // Sub nav is needed when a meeting card is selected (has a shortcode)
      return (
        _.has(this.$route, 'params.shortcode') ||
        (this.currentMeeting !== undefined && this.currentMeeting !== null)
      );
    },
    isVBM() {
      if (this.currentMeeting) {
        return this.currentMeeting.options.type === 'vbm';
      }
      return false;
    },
    localProfile() {
      return JSON.parse(sessionStorage.getItem('profile'));
    },
    meetingNavTitle() {
      return this.currentMeeting
        ? this.currentMeeting.name
        : 'Meeting Loading...';
    },
    showMeetingNotes() {
      // Hide meetings notes if vbm type meeting
      if (this.currentMeeting?.options.type === 'vbm') {
        return false;
      }
      if (this.isAdmin) {
        return true;
      }
      if (this.scopes) {
        return this.getScopeByAction(
          this.$route.params.shortcode,
          'meeting.notes.read'
        );
      }
      return false;
    },
    currentNotes() {
      if (this.currentMeeting) {
        return this.notesByShortCode(this.$route.params.shortcode);
      }
      return [];
    },
    currentAgentNotes() {
      if (this.currentMeeting) {
        return this.agentNotesByShortCode(this.$route.params.shortcode);
      }
      return [];
    },
    accountNotes() {
      if (this.currentMeetingAccount) {
        return this.accountNotesByKey(this.currentMeetingAccount.key);
      }
      return [];
    },
    totalNoteCount() {
      if (this.currentNotes && this.accountNotes && this.currentAgentNotes)
        return (
          this.currentNotes.length +
          this.accountNotes.length +
          this.currentAgentNotes.length
        );
    },
    unreadNotes() {
      if (this.currentMeeting) {
        const nodeList = this.notesByShortCode(this.$route.params.shortcode);
        const localUserKey = this.localProfile.key;

        return _.filter(
          nodeList,
          note => note.userKey !== localUserKey && note.read == false
        );
      }

      return [];
    },
    canSeeSchedulingWizardButton() {
      const { profile } = this.login;
      const isExperimental = this.$store.getters['features/experimentalMode'];
      // Check if isAdmin and isExperimental is toggled on
      if (this.isAdmin && isExperimental) {
        return true;
      }
      // Check if user has at least 1 account
      const totalAccounts = profile.nroAccounts || 0;
      if (totalAccounts >= 1) {
        return true;
      }
      return false;
    },
    totalInvoiceAmountOwing() {
      let owing = 0;
      if (
        this.outstandingAccountInvoices &&
        this.outstandingAccountInvoices.length > 0
      ) {
        this.outstandingAccountInvoices.forEach(i => {
          owing += +i.amountOwing;
        });
      }
      return owing;
    },

    showOverdueInvoicesIndicator() {
      if (this.subscriptionBalance > 0 || this.totalInvoiceAmountOwing > 0) {
        return true;
      }
      return false;
    },

    overdueReasons() {
      let html = '';

      if (this.totalInvoiceAmountOwing > 0) {
        html += '<p>Customer has unpaid invoices</p>';
      }
      if (this.subscriptionBalance > 0) {
        html += '<p>Customer has unpaid subscriptions</p>';
      }

      return html;
    },

    subscriptionBalance() {
      if (
        this.subscription &&
        this.subscription.invoiceStatus === 'payment_due' &&
        this.subscription.invoiceOwing
      ) {
        return this.subscription.invoiceOwing;
      }
      return 0;
    },
    enableChatWidget() {
      return (
        this.currentMeeting &&
        this.currentMeeting.options &&
        this.currentMeeting.options.enableChatWidget
      );
    }
  },
  mounted() {
    this.setNotesDrawer();

    if (this.enableChatWidget) {
      this.loadChat();
    }

    // Just in case the user browses to a different meeting, it'll update
    // the Helpscout beacon identity even if the other meeting doesn't have
    // chat enabled.
    this.chatIdentify({
      ...this.login.profile,
      shortCode: this.$route.params.shortcode
    });
  },
  methods: {
    ...mapActions(['doLogout']),
    ...mapActions('meetings/checklists', ['getChecklists']),
    ...mapActions('accounts', [
      'getAccount',
      'getAccountListData',
      'getAccountSubscription'
    ]),
    ...mapActions('chat', ['chatPosition']),
    ...mapActions('chat', [
      'chatInit',
      'chatIdentify',
      'chatDestroy',
      'chatPosition',
      'toggleHelpscout'
    ]),
    logout() {
      this.doLogout();
    },
    navigateToChangePassword() {
      this.$router.push({ name: 'changePassword' });
    },
    expandNotesSideBar(value) {
      this.notesDrawer = value;
      localStorage.setItem(
        `${this.currentMeetingShortCode}-notesDrawer`,
        value
      );
    },
    setNotesDrawer() {
      // Check if currentMeetingShortCode exists
      if (this.currentMeetingShortCode) {
        // If VBM, never open the notes drawer
        if (this.currentMeeting?.options.type === 'vbm') {
          this.notesDrawer = false;
          return;
        }

        // Open the notes drawer if there are unread notes (non-admin)
        if (!this.isAdmin && this.unreadNotes.length > 0) {
          this.notesDrawer = true;
          return;
        }

        // Get notesDrawer value in local storage
        const notesDrawerValue = JSON.parse(
          localStorage.getItem(`${this.currentMeetingShortCode}-notesDrawer`)
        );
        if (notesDrawerValue === null) {
          this.notesDrawer = true;
        } else {
          this.notesDrawer = notesDrawerValue;
        }
      }
    },
    goToMeetingWizard() {
      const baseUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://dash2.getquorum.com'
          : 'http://localhost:8087';
      const url = `${baseUrl}/createMeeting`;
      window.open(url, '_blank');
    },
    async refreshSubscription() {
      try {
        this.loading = true;

        // Force a fresh cache of the subscription details
        await this.getAccountSubscription({
          key: this.currentMeeting.accountKey,
          clearCache: true
        });

        // Get latest account details (including the fresh subscription data)
        await this.getAccount({
          key: this.currentMeeting.accountKey,
          includeConsents: false,
          includeAgreements: false,
          includePreferred: false,
          includeMeetings: false,
          includeAgents: true,
          includeUsers: false,
          includeSubscription: false,
          includeInvoices: false
        });

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    loadChat() {
      if (this.enableChatWidget) {
        console.log('Enabling experimental chat');
        this.chatInit().then(() => {
          console.log('Chat Loaded');
          // update position based on the drawer
          const that = this;
          setTimeout(function() {
            that.chatPosition({ shortCode: that.$route.params.shortcode });
          }, 2000);
        });

        this.chatIdentify({
          ...this.login.profile,
          shortCode: this.$route.params.shortcode
        });
      }
    },
    displayHelpscout() {
      this.toggleHelpscout();
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.gq-toolbar {
  z-index: 5;
}
.cancelButton {
  z-index: 9999;
}
.newMeetingButton {
  background: #e4853a;
  border-radius: 4px;
}
</style>
