<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Advanced Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Advanced and experimental settings.
    </div>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="displayVotingResultReport"
          @change="onChange('options.displayVotingResultReport', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display Voting Result Report / Attendance Snapshot
        </div>
        <div class="grey--text text--darken-2">
          Click this to show the option to download the voting result metrics.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="displayTallyTablesV3"
          @change="onChange('options.displayTallyTablesV3', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display V3 Tally Tables
        </div>
        <div class="grey--text text--darken-2">
          Click this to display the V3 tally tables. This will allow the
          additional votes column to be itemized.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="displayVoteResultsToggle"
          @change="onChange('options.displayVoteResultsToggle', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display Voting Results Options - V3 Tally Table
        </div>
        <div class="grey--text text--darken-2">
          Click this to display the "View Voting Results" option on top of the
          V3 tally table. This will allow switching between viewing voting
          results by either Unit Factors or a 1:1 vote breakdown (i.e., results
          tallied by shares or units).
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="displayOverviewV2"
          @change="onChange('options.displayOverviewV2', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display V2 Overview Page
        </div>
        <div class="grey--text text--darken-2">
          Click this to toggle on the V2 Overview page (admins only). This page
          is intended for self-serve clients.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableChatWidget"
          @change="onChange('options.enableChatWidget', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Live Support Chat Beacon
        </div>
        <div class="grey--text text--darken-2">
          Click this to enable the Help Scout live support widget for all users
          in this meeting.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-2">
      <div class="pr-3">
        <v-checkbox
          v-model="enableGetStatsV2"
          @change="onChange('options.enableGetStatsV2', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Get Meeting Stats v2
        </div>
        <div class="grey--text text--darken-2">
          Click this option to optimize meeting stats process for campaigns with
          large data.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableFloridaNominationTool"
          @change="onChange('options.enableFloridaNominationTool', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Florida Candidate Nomination Tool
        </div>
        <div class="grey--text text--darken-2">
          Toggles the Florida Candidate Nomination Tool for this meeting.
          Differences from the default tool:<br />
          <ul>
            <li>Generates "Notice of Intent" form</li>
            <li>No disclosure questions or form</li>
          </ul>
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="enableCheckinPdf"
          @change="onChange('options.enableCheckinPdf', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Check-In PDF
        </div>
        <div class="grey--text text--darken-2">
          This will enable check-in pdf for this campaign.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="rsvpConfirmationPage"
          @change="onChange('options.rsvpConfirmationPage', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable RSVP Confirmation Link
        </div>
        <div class="grey--text text--darken-2">
          Replaces the link sent in notices for all RSVP buttons to a dedicated
          confirmation page on the RTV app. Applies to: <br />
          [[RTV_RSVP "Register Now"]], [[RSVP "Register Now"]], [[BUTTON: RSVP]]
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="useExclusionaryRules"
          @change="onChange('options.useExclusionaryRules', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Use exclusionary settings
        </div>
        <div class="grey--text text--darken-2">
          Enable this to apply exclusionary setting for units. <br />
          For Ontario meetings, units who submitted proxies and are in-arrears
          will be excluded.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="enableSplitRegistration"
          @change="onChange('options.enableSplitRegistration', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable separate RSVP from Webcast Registration
        </div>
        <div class="grey--text text--darken-2">
          Users landing on RTV will no longer be auto rsvp'd. Uses separate
          routes for RSVP status and webcast registration instead of the
          combined approach.
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'MeetingSettingsAdvancedInput',
  components: {},
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initState();
  },
  data() {
    return {
      enableChatWidget: false,
      displayOverviewV2: false,
      displayVoteResultsToggle: false,
      displayTallyTablesV3: false,
      displayVotingResultReport: false,
      enableRtvRandomDelay: false,
      enableGetStatsV2: false,
      enableFloridaNominationTool: false,
      enableCheckinPdf: false,
      rsvpConfirmationPage: false,
      useExclusionaryRules: false,
      enableSplitRegistration: false
    };
  },
  computed: {
    rtvBallotsNotionDoc() {
      return process.env.VUE_APP_NOTION_DOC_RTV_BALLOTS || '';
    }
  },
  methods: {
    initState() {
      this.displayTallyTablesV3 = this.options.displayTallyTablesV3;
      this.enableChatWidget = this.options.enableChatWidget;
      this.displayVoteResultsToggle = this.options.displayVoteResultsToggle;
      this.displayVotingResultReport = this.options.displayVotingResultReport;
      this.enableRtvRandomDelay = this.options.enableRtvRandomDelay;
      this.enableGetStatsV2 = this.options.enableGetStatsV2;
      this.enableFloridaNominationTool = this.options.enableFloridaNominationTool;
      this.enableCheckinPdf = this.options.enableCheckinPdf;
      this.rsvpConfirmationPage = this.options.rsvpConfirmationPage;
      this.useExclusionaryRules = this.options.useExclusionaryRules;
      this.enableSplitRegistration = this.options.enableSplitRegistration;
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style></style>
