'use strict';

/**
 * @fileoverview Vuex module for accounts
 */

import _ from 'lodash';
import Vue from 'vue';

import { removeEmptyProperty } from '@/lib/property-helpers';

// API Calls
import * as acctApi from '@/api/accounts';
import { getRecipe } from '@/api/recipes';

const state = {
  accountList: {},
  customerTypes: [],
  regions: [],
  pagination: {
    descending: true,
    sortBy: 'createdAt',
    search: '',
    searchByShortcodeOnly: false,
    page: 1,
    rowsPerPage: 25,
    totalItems: 0,
    rowsPerPageItems: [25, 50, 100, 200]
  },
  filters: [],
  subFilter: 'all',
  agentsFilter: 'all',
  subscription: null,
  accountInvoices: null,
  dataLastFetchedAt: null,
  subscriptionSummary: [],
  contacts: []
};

const getters = {
  accountList: state => state.accountList,
  accountListData: state => Object.values(state.accountList),
  accountListDataNameShort: state =>
    Object.values(state.accountList).map(({ nameShort }) => nameShort),
  accountListDataName: state =>
    Object.values(state.accountList).map(({ name }) => name),
  accountByKey: state => key => state.accountList[key],
  customerTypes: state => state.customerTypes,
  regions: state => state.regions,
  dataLastFetchedAt: state => state.dataLastFetchedAt,
  pagination: state => state.pagination,
  subscription: state => state.subscription || null,
  accountInvoices: state => state.accountInvoices || [],
  filters: state => state.filters,
  subFilter: state => state.subFilter,
  agentsFilter: state => state.agentsFilter,
  accountNotesByKey: state => key => {
    const account = state.accountList[key];
    return _.get(account, 'data.notes', []);
  },
  accountUnassignedChecklistsByKey: state => key => {
    const account = state.accountList[key];
    if (account) {
      const incompleteChecklists = account.incompleteChecklists;
      if (incompleteChecklists) {
        return incompleteChecklists.filter(c => !c.assignees);
      }
    }

    return [];
  },
  accountIncompleteChecklistsByKey: state => key => {
    const account = state.accountList[key];
    return _.get(account, 'incompleteChecklists', []);
  },
  subscriptionSummary: state => state.subscriptionSummary,
  accountContacts: state => state.contacts || []
};

const actions = {
  /**
   * Get the latest Accounts from API
   *
   */
  async getAccountListData({ commit }) {
    try {
      const res = await acctApi.getAccountsList({
        page: state.pagination.page,
        size: state.pagination.rowsPerPage,
        search: state.pagination.search ? state.pagination.search : '',
        searchByShortcodeOnly: state.pagination.searchByShortcodeOnly,
        sort: state.pagination.sortBy,
        descending: state.pagination.descending,
        filters: state.filters,
        subscriptions: state.subFilter,
        agents: state.agentsFilter
      });

      const { accounts = [], totalNum = 0 } = res.data;

      commit('SET_ACCOUNT_LIST_DATA', { accounts, totalNum });
    } catch (err) {
      console.error('ERROR: getAccountListData action', err);
      throw err;
    }
  },

  /**
   * Create a new account in API
   *
   * @param  {Object} account  new account to create
   * @return {String}          the newly created account's key
   */
  async createAccount({ commit }, account) {
    try {
      const region = account.region;

      let currency = 'CAD';

      if (!['ON', 'AB', 'BC', 'SK', 'other-can'].includes(region)) {
        currency = 'USD';
      }

      const newAccount = { ...account, billingCurrency: currency };

      const res = await acctApi.postNewAccount(newAccount);
      commit('SET_ACCOUNT_IN_LIST', { key: res.data.key, account: res.data });
      return res.data.key;
    } catch (err) {
      console.error('ERROR: createAccount action', err);
      throw err;
    }
  },

  /**
   * Get the latest Account identified by Key from API
   *
   * @param  {String} key the account key
   */
  async getAccount(
    { commit, dispatch },
    {
      key,
      includeConsents,
      includeAgreements,
      includePreferred,
      includeMeetings,
      includeAgents,
      includeUsers,
      includeSubscription,
      includeAccountInvoices,
      includeInvoices
    }
  ) {
    try {
      const res = await acctApi.getAccount(key);
      const acct = res.data;

      const accountWithSearchData = {
        ...res.data,
        searchField: `${acct.nameShort} - ${acct.address.address}`
      };

      commit('SET_ACCOUNT_IN_LIST', {
        key,
        account: accountWithSearchData
      });

      if (includeConsents) {
        dispatch('getAccountConsents', key);
      }

      if (includeAgreements) {
        dispatch('getAccountAgreements', key);
      }

      if (includeUsers) {
        dispatch('getAccountUsers', key);
      }

      if (includeMeetings) {
        dispatch('getAccountMeetings', key);
      }

      if (includePreferred) {
        dispatch('getAccountPreferredManagers', key);
      }

      if (includeAgents) {
        dispatch('getAccountAgents', key);
      }

      if (includeSubscription) {
        dispatch('getAccountSubscription', { key });
      }

      if (includeInvoices) {
        dispatch('invoices/getInvoices', { accountKey: key }, { root: true });
      }

      if (includeAccountInvoices) {
        dispatch('getAccountInvoices', { key });
      }

      commit('SET_DATA_LAST_FETCHED_AT');
    } catch (err) {
      console.error('ERROR: getAccount action', err);
      throw err;
    }
  },

  /**
   * Update the account via API
   *
   * @param  {String} key     the account key
   * @param  {Object} payload the update payload
   */
  async updateAccount({ dispatch }, { key, payload }) {
    try {
      const accountObj = removeEmptyProperty(_.cloneDeep(payload));
      const removedConsents = _.omit(accountObj, ['consentsListData']);
      await acctApi.putAccount(key, removedConsents);
      dispatch('getAccount', {
        key,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.error('ERROR: updateAccount action', err);
      throw err;
    }
  },

  /**
   * Update the account notes
   *
   * @param  {String} key     the account key
   * @param  {Object} notes the update payload
   */
  async updateAccountNotes({ dispatch }, { key, notes }) {
    try {
      await acctApi.updateAccountNotes({ key, notes });
      dispatch('getAccount', {
        key,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.error('ERROR: updateAccountNotes action', err);
      throw err;
    }
  },

  /**
   * Get the list of consenting email addresses for this account and set in
   * store.
   *
   * @param  {String} key     the account key
   */
  async getAccountConsents({ commit }, key) {
    try {
      const res = await acctApi.getAccountActiveConsents(key);
      commit('SET_ACCOUNT_CONSENT_LIST', { key, consents: res.data });
    } catch (err) {
      console.error('ERROR: getAccountConsents action', key, err);
      throw err;
    }
  },

  /**
   * Add consents or rejections an account
   *
   * @param  {String} key    - the Account Key
   * @param  {String} emails - the emails separated by commas
   * @param  {String} status - consented or rejected
   * @param  {String} type   - how this came about
   */
  async addAccountConsents({ dispatch }, { key, emails, status, type }) {
    try {
      await acctApi.postAccountActiveConsents(key, emails, status, type);
      dispatch('getAccountConsents', key);
    } catch (err) {
      console.error('ERROR: addAccountConsents action', key, emails, err);
      throw err;
    }
  },

  /**
   * Add consents or rejections an account
   *
   * @param  {String} key    - the Account Key
   * @param  {String} emails - the emails separated by commas
   */
  async deleteAccountConsents({ dispatch }, { key, emails }) {
    try {
      await acctApi.deleteAccountConsents(key, emails);
      dispatch('getAccountConsents', key);
    } catch (err) {
      console.error('ERROR: deleteAccountConsents action', key, emails, err);
      throw err;
    }
  },

  /**
   * Get the list of service agreements for this account and set in
   * store.
   *
   * @param  {String} key     the account key
   */
  async getAccountAgreements({ commit }, key) {
    try {
      const res = await acctApi.getAccountAgreements(key);
      commit('SET_ACCOUNT_AGREEMENT_LIST', { key, agreements: res.data });
    } catch (err) {
      console.error('ERROR: getAccountAgreements action', key, err);
      throw err;
    }
  },

  /**
   * Get the list of campaigns belonging to this account and set it into store
   * @param {string}    key         - the account key
   */
  async getAccountMeetings({ commit }, key) {
    try {
      // Loop through all the shortcodes and retrieve the meeting data
      const res = await acctApi.getAccountMeetings({
        key,
        sortDir: 'desc'
      });

      commit('SET_ACCOUNT_MEETING_LIST', { key, meetings: res.data });
    } catch (err) {
      console.error('ERROR: getAccountMeetings action', key, err);
      throw err;
    }
  },

  /**
   * Get the list of service agreements for this account and set in
   * store.
   *
   * @param  {String} key     the account key
   */
  async getAccountUsers({ commit }, key) {
    try {
      const res = await acctApi.getAccountUsers(key);
      commit('SET_ACCOUNT_USERS_LIST', { key, users: res.data });
    } catch (err) {
      console.error('ERROR: getAccountUsers action', key, err);
      throw err;
    }
  },

  /**
   * Get the list of agents associated with accountKey
   *
   * @param  {String} key     the account key
   */
  async getAccountAgents({ commit }, key) {
    try {
      const res = await acctApi.getAccountAgents(key);
      commit('SET_ACCOUNT_AGENTS_LIST', { key, agents: res.data });
    } catch (err) {
      console.error('ERROR: getAccountAgents action', key, err);
      throw err;
    }
  },

  /**
   * Add a membership for this user
   * @param  {Object} obj            - the params
   * @param  {String} obj.email      - the user email
   * @param  {String} obj.accountKey - the account for the membership
   * @param  {String} [params.firstName]  - first name
   * @param  {String} [params.lastName]   - last name
   */
  async addAccountUser(
    { dispatch },
    { email, accountKey, firstName, lastName }
  ) {
    try {
      await acctApi.addAccountUser({
        email,
        key: accountKey,
        firstName,
        lastName
      });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a membership for this user
   * @param  {Object} obj            - the params
   * @param  {String} obj.email      - the user email
   * @param  {String} obj.accountKey - the account for the membership
   */
  async removeAccountUser({ dispatch }, { email, accountKey }) {
    try {
      await acctApi.deleteAccountUser({ email, key: accountKey });
    } catch (err) {
      throw err;
    }
  },

  /**
   * remove a membership for this agent and account
   * @param  {Object}   obj            - the params
   * @param  {String}   obj.accountKey - the account for the membership
   * @param   {String}  obj.agentKey   - the agent key
   */
  async removeAgentAccount({ dispatch }, accountAgentPayload) {
    try {
      const { agentKey, accountKey } = accountAgentPayload;
      await acctApi.deleteAgentAccount(agentKey, accountKey);
      dispatch('getAccountAgents', accountKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a membership for this agent and account
   * @param  {Object} obj            - the params
   * @param  {String} obj.accountKey - the account for the membership
   */
  async createAgentAccount({ dispatch }, accountAgentPayload) {
    try {
      const { accountKey } = accountAgentPayload;
      await acctApi.createAgentAccount(accountAgentPayload);
      dispatch('getAccountAgents', accountKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Create a new service agreement for an account
   * @param  {String} type          type of agreement
   * @param  {String} accountKey    account to create agreement for
   * @param  {String} [signedAt]    date the agreement was signed
   * @param  {String} [expiresAt]   date the agreement expires
   * @param  {String} [description] additional notes for the agreement
   */
  async createAccountAgreement(
    { dispatch },
    { type, accountKey, signedAt, expiresAt, description }
  ) {
    try {
      const payload = { type, accountKey, signedAt, expiresAt, description };
      // Create the Agreement using existing module
      await dispatch('serviceAgreements/createBlankAgreement', payload, {
        root: true
      });
      // Update with the latest set of data
      await dispatch('getAccountAgreements', accountKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get the default customer types
   */
  async getCustomerTypes({ commit }) {
    try {
      const res = await getRecipe('accounts.customer_types');

      const customerTypes = _.get(res.data, 'data.customerTypes', []);
      commit('SET_CUSTOMER_TYPES', customerTypes);
    } catch (err) {
      console.error('ERROR: getCustomerTypes', err);
      throw err;
    }
  },

  /**
   * Get the default customer types
   */
  async getRegions({ commit }) {
    try {
      const res = await getRecipe('accounts.regions');

      const regions = _.get(res.data, 'data.regions', []);
      commit('SET_CUSTOMER_REGIONS', regions);
    } catch (err) {
      console.error('ERROR: getCustomerRegions', err);
      throw err;
    }
  },

  /**
   * Return list of preferred managers for an account
   *
   * @param  {String} accountKey    - account key
   */
  async getAccountPreferredManagers({ dispatch, commit }, accountKey) {
    try {
      const res = await acctApi.getAccountPreferredManagers(accountKey);
      commit('SET_ACCOUNT_PREFERRED_MANAGERS', {
        key: accountKey,
        managers: res.data
      });
      dispatch('getAccountIncompleteChecklists', { accountKey });
    } catch (err) {
      console.error('ERROR: getAccountPreferredManagers', err);
      throw err;
    }
  },

  /**
   * Delete an account's preferred manager (CSM or VMM)
   *
   * @param  {String} accountKey    - account key
   * @param  {String} userType      - preferred manager type (csm, vmm)
   */
  async removeAccountPreferredManager({ dispatch }, { accountKey, userType }) {
    try {
      await acctApi.deleteAccountPreferredManager({ accountKey, userType });
      dispatch('getAccountPreferredManagers', accountKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Add a preferred manager to an account
   *
   * @param  {String} accountKey    - account key
   * @param  {String} userKey       - user key
   * @param  {String} userType      - preferred manager type (csm, vmm)
   */
  async addAccountPreferredManager(
    { dispatch },
    { accountKey, userKey, userType }
  ) {
    try {
      await acctApi.addAccountPreferredManager({
        accountKey,
        userKey,
        userType
      });
      dispatch('getAccountPreferredManagers', accountKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update the preferred manager tied to an account
   * (Delete old manager, add new manager)
   *
   * @param  {String} accountKey    - account key
   * @param  {String} userKey       - user key
   * @param  {String} userType      - preferred manager type (csm, vmm)
   */
  async updateAccountPreferredManager(
    { dispatch },
    { accountKey, userKey, userType }
  ) {
    try {
      await acctApi.deleteAccountPreferredManager({ accountKey, userType });
      await acctApi.addAccountPreferredManager({
        accountKey,
        userKey,
        userType
      });
      dispatch('getAccountPreferredManagers', accountKey);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Returns list of incomplete checklists for an account
   *
   * @param  {String} accountKey    - account key
   */
  async getAccountIncompleteChecklists({ commit }, { accountKey }) {
    try {
      const res = await acctApi.getIncompleteChecklists({ accountKey });
      commit('SET_ACCOUNT_INCOMPLETE_CHECKLISTS', {
        key: accountKey,
        checklists: res.data
      });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update server-side pagination
   *
   * @param  {String} options    - pagination payload
   */
  async setPagination({ commit }, options) {
    try {
      commit('SET_PAGINATION', options);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update server-side filters
   *
   * @param  {String} filters    - filters
   */
  async setFilters({ commit }, { filters, subFilter, agentsFilter }) {
    try {
      commit('SET_FILTERS', filters);
      commit('SET_SUB_FILTER', subFilter);
      commit('SET_AGENTS_FILTER', agentsFilter);
    } catch (err) {
      throw err;
    }
  },

  /**
   * Update server-side search term
   *
   * @param  {String}  search         - search term
   * @param  {Boolean} shortcodeOnly  - whether we're searching by shortcode only
   */
  async setSearch({ commit }, { search, shortcodeOnly }) {
    try {
      commit('SET_SEARCH', { search, shortcodeOnly });
    } catch (err) {
      throw err;
    }
  },

  /**
   * Get the subscription associated with accountKey
   *
   * @param  {String}  key           the account key
   * @param  {Boolean} clearCache    if true, upsert the cached subscription record (regardless of expiry time)
   */
  async getAccountSubscription({ commit }, { key, clearCache = false }) {
    try {
      const res = await acctApi.getAccountSubscription(key, clearCache);
      commit('SET_ACCOUNT_SUBSCRIPTION', res.data);
    } catch (err) {
      console.log('getAccountSubscription action', key, err);
      commit('SET_ACCOUNT_SUBSCRIPTION', null);
    }
  },

  /**
   * Get the invoices associated with accountKey
   *
   * @param  {String}  key           the account key
   */
  async getAccountInvoices({ commit }, { key }) {
    try {
      const res = await acctApi.getAccountInvoices(key);

      commit('SET_ACCOUNT_INVOICES', res.data);
    } catch (err) {
      console.log('getAccountInvoices action', key, err);
      commit('SET_ACCOUNT_INVOICES', null);
    }
  },

  /**
   * Add the subscription proof
   *
   * @param  {String}  key                the account key
   * @param  {Boolean} subscriptionProof  the subscription proof
   */
  async addAccountSubscriptionProof(
    { dispatch },
    { key, subscriptionProof = {} }
  ) {
    try {
      await acctApi.addAccountSubscriptionProof(key, subscriptionProof);
      dispatch('getAccount', {
        key,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.log('addAccountSubscriptionProof action', key, err);
    }
  },

  /**
   * Get the subscription usage summary
   *
   * @param  {String}  key           the account key
   * @param  {Boolean} clearCache    if true, upsert the cached subscription record (regardless of expiry time)
   */
  async getAccountSubscriptionUsageSummary({ commit }, { key }) {
    try {
      const res = await acctApi.getAccountSubscriptionUsageSummary(key);
      commit('SET_SUBSCRIPTION_SUMMARY', res.data);
    } catch (err) {
      console.log('getAccountSubscriptionUsageSummary action', key, err);
      commit('SET_SUBSCRIPTION_SUMMARY', []);
    }
  },

  /**
   * Retrieves the account's meetings by subscription period.
   *
   * @param {Object} params - the parameters object
   * @param {string} params.key - the account key
   * @param {string} params.type - the meeting type
   * @param {string} params.startDate - the start date
   * @param {string} params.endDate - the end date
   * @returns {Promise} a promise that resolves to the account meeting
   */
  async getAccountMeetingBySubscritionPeriod(
    { commit },
    { key, type, startDate, endDate }
  ) {
    try {
      return await acctApi.getAccountMeetingBySubscritionPeriod({
        accountKey: key,
        type,
        startDate,
        endDate
      });
    } catch (err) {
      console.log('getAccountMeetingBySubscritionPeriod action', key, err);
    }
  },

  /**
   * Get the contacts associated with accountKey
   *
   * @param  {String}  key  the account key
   */
  async getAccountContacts({ commit }, { key }) {
    try {
      const res = await acctApi.getAccountContacts(key);
      commit('SET_ACCOUNT_CONTACTS', res.data);
    } catch (err) {
      console.log('getAccountContacts action', key, err);
      commit('SET_ACCOUNT_CONTACTS', []);
    }
  },

  /**
   * Reset state of the contacts associated with accountKey
   *
   * @param  {String}  key  the account key
   */
  async resetAccountContacts({ commit }) {
    commit('SET_ACCOUNT_CONTACTS', []);
  },

  /**
   * Get the contacts associated with accountKey
   *
   * @param  {String}  key  the account key
   */
  async associateContacts({ dispatch }, { key, contactIds }) {
    try {
      const res = await acctApi.associateContacts({ key, contactIds });
      await dispatch('getAccountContacts', { key });
      return res.data;
    } catch (err) {
      console.log('associateContacts action', key, err);
    }
  },

  /**
   * Get the contacts associated with accountKey
   *
   * @param  {String}  key  the account key
   */
  async removeContactAssociation({ dispatch }, { key, contactId }) {
    try {
      await acctApi.removeAssociation({ key, contactId });
      await dispatch('getAccountContacts', { key });
    } catch (err) {
      console.log('removeContactAssociation action', key, err);
    }
  },

  /**
   * Update the contact association
   *
   * @param  {String}  key  the account key
   */
  async updateContactAssociation({ dispatch }, { key, contactIds, types }) {
    try {
      await acctApi.updateAssociation({ key, contactIds, types });

      dispatch('getAccount', {
        key,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.log('updateAssociation action', key, err);
    }
  },

  /**
   * Link GQ account with hubspot company
   *
   * @param  {String} accountKey - the Account Key
   * @param  {String} companyId - company id
   */
  async linkCompany({ dispatch }, { accountKey, companyId }) {
    try {
      await acctApi.linkCompany({ accountKey, companyId });
      await dispatch('getAccount', {
        key: accountKey,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.log('linkCompany action', accountKey, err);
      throw err;
    }
  },

  /**
   * UnLink GQ account with hubspot company
   *
   * @param  {String} accountKey - the Account Key
   */
  async unlinkCompany({ dispatch }, { accountKey }) {
    try {
      await acctApi.unlinkCompany({ accountKey });
      await dispatch('getAccount', {
        key: accountKey,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.log('unlinkCompany action', accountKey, err);
      throw err;
    }
  },

  /**
   *
   * Get Membership from Association
   *
   * @param  {String} accountKey     - the Account Key
   * @param  {String} integrationKey - the account integration key
   */
  async getAssociationMemberships(_, { accountKey, integrationKey }) {
    try {
      const res = await acctApi.getAssociationMemberships({
        accountKey,
        integrationKey
      });

      if (res.data.error) {
        const error = new Error();
        error.response = {
          status: res.data.status,
          data: 'Unable to get association memberships'
        };

        throw error;
      }

      return res.data;
    } catch (err) {
      console.log('getAssociationMemberships action', accountKey, err);
      throw err;
    }
  },

  /**
   *
   * Upsert Account Integration
   *
   * @param  {String} accountKey     - the Account Key
   * @param  {String} integrationKey - the integration name of the association
   * @param  {String} code           - the association code
   * @param  {String} company        - vantaca company
   */
  async upsertIntegration(
    { dispatch },
    { accountKey, integrationKey, code, company }
  ) {
    try {
      await acctApi.upsertIntegration({
        accountKey,
        integrationKey,
        code,
        company
      });

      await dispatch('getAccount', {
        key: accountKey,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.log('getAssociationMemberships action', accountKey, err);
      throw err;
    }
  },
  /**
   *
   * Remove Account Integration
   *
   * @param  {String} accountKey            - the Account Key
   * @param  {String} accountIntegrationKey - the Account Integration Key
   */
  async removeAccIntegration(
    { dispatch },
    { accountKey, accountIntegrationKey }
  ) {
    try {
      await acctApi.removeAccIntegration({
        accountKey,
        accountIntegrationKey
      });

      await dispatch('getAccount', {
        key: accountKey,
        includeConsents: true,
        includeAgreements: true,
        includeUsers: true,
        includeMeetings: true,
        includePreferred: true,
        includeAgents: true,
        includeSubscription: true,
        includeInvoices: true
      });
    } catch (err) {
      console.log('removeAccIntegration action', accountKey, err);
      throw err;
    }
  },

  /**
   *
   * Manual sync for membership info for meeting based on account integration
   *
   * @param  {String} accountKey            - the Account Key
   * @param  {String} accountIntegrationKey - the Account Integration Key
   * @param  {String} shortCode             - the short code
   * @param  {Boolean} force                - force sync
   */
  async syncMembershipsData(
    _,
    { accountKey, accountIntegrationKey, shortCode, force }
  ) {
    try {
      await acctApi.syncMembershipsData({
        accountKey,
        accountIntegrationKey,
        shortCode,
        force
      });
    } catch (err) {
      console.log('syncMembershipsData action', accountKey, err);
      throw err;
    }
  }
};

const mutations = {
  SET_DATA_LAST_FETCHED_AT(state) {
    state.dataLastFetchedAt = new Date();
  },
  SET_ACCOUNT_LIST_DATA(state, { accounts, totalNum }) {
    state.accountList = _.keyBy(accounts, 'key');
    state.pagination.totalItems = parseInt(totalNum);
  },

  SET_ACCOUNT_IN_LIST(state, { key, account }) {
    Vue.set(state.accountList, key, account);
  },

  SET_ACCOUNT_CONSENT_LIST(state, { key, consents }) {
    Vue.set(state.accountList[key], 'consentsListData', consents);
  },

  SET_ACCOUNT_AGREEMENT_LIST(state, { key, agreements }) {
    Vue.set(
      state.accountList[key],
      'agreementsList',
      _.keyBy(agreements, 'key')
    );
  },

  SET_ACCOUNT_MEETING_LIST(state, { key, meetings }) {
    Vue.set(state.accountList[key], 'meetings', meetings);
  },

  SET_ACCOUNT_USERS_LIST(state, { key, users }) {
    Vue.set(state.accountList[key], 'users', users);
  },

  SET_ACCOUNT_AGENTS_LIST(state, { key, agents }) {
    Vue.set(state.accountList[key], 'agents', agents);
  },

  SET_ACCOUNT_INVOICE_LIST(state, { key, invoices }) {
    Vue.set(state.accountList[key], 'invoices', invoices);
  },

  SET_CUSTOMER_TYPES(state, customerTypes) {
    state.customerTypes = customerTypes;
  },

  SET_CUSTOMER_REGIONS(state, regions) {
    state.regions = regions;
  },

  SET_ACCOUNT_PREFERRED_MANAGERS(state, { key, managers }) {
    Vue.set(state.accountList[key], 'preferredManagers', managers);
  },

  SET_ACCOUNT_INCOMPLETE_CHECKLISTS(state, { key, checklists }) {
    Vue.set(state.accountList[key], 'incompleteChecklists', checklists);
  },

  SET_PAGINATION(state, options) {
    state.pagination = options;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },

  SET_SUB_FILTER(state, filter) {
    state.subFilter = filter;
  },

  SET_AGENTS_FILTER(state, filter) {
    state.agentsFilter = filter;
  },

  SET_SEARCH(state, { search, shortcodeOnly }) {
    state.pagination.search = search;
    state.pagination.searchByShortcodeOnly = shortcodeOnly;
  },

  SET_ACCOUNT_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  SET_ACCOUNT_INVOICES(state, invoices) {
    state.accountInvoices = invoices;
  },

  SET_SUBSCRIPTION_SUMMARY(state, data) {
    state.subscriptionSummary = data;
  },

  SET_ACCOUNT_CONTACTS(state, contacts) {
    state.contacts = contacts;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
