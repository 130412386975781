<template>
  <div>
    <v-list class="pa-1">
      <v-list-tile>
        <v-list-tile-title>Filtering Type</v-list-tile-title>
      </v-list-tile>
    </v-list>

    <v-list dense>
      <v-divider class="mb-3"></v-divider>
      <v-btn-toggle
        :value="filterType"
        mandatory
        class="mx-2"
        @change="updateFilteringType"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn flat value="and" v-on="on">Matches All</v-btn>
          </template>
          <span>Units must include all filters</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn flat value="or" v-on="on">Matches Any</v-btn>
          </template>
          <span>Units must include at least one filter</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-list>

    <v-list class="pa-1">
      <v-list-tile>
        <v-list-tile-title>
          Filters
        </v-list-tile-title>
        <v-list-tile-action>
          <v-btn flat icon @click="clearFilters"><v-icon>close</v-icon> </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-list dense>
      <v-divider class="mb-3"></v-divider>

      <v-btn-toggle
        :value="filters"
        @change="setFiltersAndUpdateUnits"
        multiple
        class="btn-group px-2"
      >
        <v-btn
          v-for="item in mappedFilterList"
          :key="item.value"
          :value="item.value"
          :color="item.color"
          flat
          small
          class="py-1"
          ><v-icon class="mr-1">{{ item.icon }}</v-icon>
          {{ item.label }}
        </v-btn>

        <v-badge right overlap color="red">
          <template v-slot:badge>
            <span
              v-if="currentMeeting.stats.testVoterStats.totalTestVoters > 0"
            >
              <span>{{
                currentMeeting.stats.testVoterStats.totalTestVoters
              }}</span>
            </span>
          </template>

          <v-btn
            flat
            value="testVotersOnly"
            color="blue-grey lighten-1"
            class="py-1"
          >
            <v-icon class="mr-1">account_box</v-icon>Test Voters
          </v-btn>
        </v-badge>

        <v-badge right overlap color="red">
          <template v-slot:badge>
            <span v-if="multiUnitOwners > 0">
              <v-icon color="white">priority_high</v-icon>
            </span>
          </template>

          <v-btn
            flat
            value="multiUnitOwners"
            color="cyan lighten-1"
            class="py-1"
          >
            <v-icon class="mr-1">group</v-icon>Multi-unit owners
          </v-btn>
        </v-badge>

        <v-badge right overlap color="red">
          <v-btn
            flat
            value="selfRegistered"
            color="blue lighten-1"
            class="py-1"
          >
            <v-icon class="mr-1">confirmation_number</v-icon>Self-Registered
          </v-btn>
        </v-badge>
      </v-btn-toggle>
    </v-list>

    <v-list class="pa-1" v-if="showConsentFilters">
      <v-list-tile>
        <v-list-tile-title>Consent Filters</v-list-tile-title>
      </v-list-tile>
    </v-list>
    <v-list dense v-if="showConsentFilters">
      <v-divider class="mb-3"></v-divider>

      <v-btn-toggle
        v-if="consentFormat === 'multi-consent'"
        :value="filters"
        @change="setFiltersAndUpdateUnits"
        multiple
        class="btn-group px-2"
      >
        <v-btn
          v-for="item in multiConsentFilters"
          :key="item.value"
          :value="item.value"
          :color="item.color"
          flat
          small
          class="py-1"
          ><v-icon class="mr-1">{{ item.icon }}</v-icon>
          {{ item.label }}
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle
        v-else
        :value="filters"
        @change="setFiltersAndUpdateUnits"
        multiple
        class="btn-group px-2"
      >
        <v-btn
          v-for="item in consentFilters"
          :key="item.value"
          :value="item.value"
          :color="item.color"
          flat
          small
          class="py-1"
          ><v-icon class="mr-1">{{ item.icon }}</v-icon>
          {{ item.label }}
        </v-btn>
      </v-btn-toggle>
    </v-list>

    <v-list class="pa-1">
      <v-list-tile>
        <v-list-tile-title>Custom Filters</v-list-tile-title>
      </v-list-tile>
    </v-list>
    <v-list dense>
      <v-divider class="mb-3"></v-divider>
      <custom-data-select
        :data="customFilters"
        @update="updateCustomFilter"
        class="px-2"
      />
    </v-list>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import CustomDataSelect from '@/components/CustomDataSelect';

export default {
  name: 'MeetingTypeFilter',
  components: {
    CustomDataSelect
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customFields: [],
      selected: [],
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('meetings/units', [
      'filterList',
      'filterType',
      'filters',
      'customFilters',
      'consentFilters',
      'multiConsentFilters'
    ]),
    multiUnitOwners: {
      get: function() {
        return this.$store.getters['meetings/units/meetingUnitMultiOwners'];
      }
    },
    enableCustomFieldInput() {
      return (
        this.customFieldName !== undefined && this.customFieldName.length > 0
      );
    },
    showConsentFilters() {
      // All formats other than email-on-file may show the consent filters
      // For existing meetings with no consentFormat, the filters will still be visible
      return this.currentMeeting.options.consentFormat !== 'email-on-file';
    },
    consentFormat() {
      return this.currentMeeting.options?.consentFormat;
    },
    mappedFilterList() {
      let filters = [...this.filterList];
      if (
        this.currentMeeting.options?.votingOption === 'Proxy' ||
        this.currentMeeting.options?.votingOption === 'Ballot'
      ) {
        return filters.filter(f => f.value !== 'ballotOnly');
      } else if (this.currentMeeting.options?.votingOption === 'Proxy-Ballot') {
        return filters.filter(f => f.value !== 'proxyOnly');
      }

      return filters;
    }
  },
  methods: {
    ...mapActions('meetings/units', [
      'setFilters',
      'setFilterType',
      'getMeetingUnits',
      'setCustomFilters'
    ]),
    updateCustomFilter(value) {
      this.setCustomFilters(value);

      if (!value || value.length === 0) {
        this.removeCustomDataFilter();
      } else if (this.filters.indexOf('customData') < 0) {
        this.filters.push('customData');
      }
      this.setFiltersAndUpdateUnits(this.filters);
    },
    removeCustomDataFilter() {
      const filterFound = this.filters.indexOf('customData');
      if (filterFound >= 0) {
        this.filters.splice(filterFound, 1);
      }
    },
    updateFilteringType(type) {
      this.setFilterType(type);
      this.setFiltersAndUpdateUnits(this.filters);
    },
    async setFiltersAndUpdateUnits(filters) {
      this.$emit('update-filters', filters);
    },
    clearFilters() {
      this.setFilters([]);
      this.setFiltersAndUpdateUnits(this.filters);
      this.updateCustomFilter();
    }
  }
};
</script>

<style scoped>
.btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
