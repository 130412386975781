var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasPermissions)?_c('v-layout',{staticClass:"mt-3",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"slot":"activator","color":"primary"},nativeOn:{"click":function($event){_vm.showAddDialog = true}},slot:"activator"},[_vm._v(" Add Integration ")])],1)],1):_vm._e(),(!_vm.hasIntegration)?_c('v-layout',{staticClass:"mt-3",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.hasAgentIntegrationAvailable)?_c('v-alert',{attrs:{"value":true,"type":"warning","outline":""}},[_vm._v(" An integration may be available for this customer. This account has an agent marked with a possible integration ")]):_c('v-alert',{attrs:{"value":true,"type":"info","outline":""}},[_vm._v(" This account has no integrations. ")])],1)],1):_vm._e(),(_vm.hasIntegration)?_c('v-layout',{staticClass:"mt-3",attrs:{"row":""}},_vm._l((_vm.currentAccount.integrations),function(integration){return _c('v-flex',{key:integration.name,attrs:{"xs4":""}},[_c('integration-card',{attrs:{"icon":"link","title":_vm._f("startCase")(integration.name),"hide-edit":!_vm.hasPermissions,"disabled":!_vm.hasChanges,"saving":_vm.saving,"downloading":_vm.downloading},on:{"save":function($event){return _vm.onSave(integration)},"edit":function($event){return _vm.setState(
            {
              assocCode: integration.assocCode,
              company: integration.company
            },
            $event
          )},"cancel":function($event){return _vm.setState(null, $event)}},scopedSlots:_vm._u([{key:"default",fn:function({ edit, downloading }){return [_c('v-card-text',[(!edit)?_c('p',[_vm._v(" Click the button below to download the membership data in CSV format. ")]):_vm._e(),(edit)?_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"label":"Association code","placeholder":"Association code","value":integration.assocCode},on:{"input":function($event){return _vm.handleUpdate('assocCode', $event)}}}):_vm._e(),(edit)?_c('v-select',{staticClass:"mt-2 pt-0",attrs:{"label":"Integration company","items":_vm.companies[integration.name] || [],"value":integration.company,"item-text":"name","item-value":"key"},on:{"change":function($event){return _vm.handleUpdate('company', $event)}}}):_vm._e()],1),(!edit)?_c('v-card-actions',{staticClass:"justify-center"},[(_vm.hasPermissions)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"red","outline":""},nativeOn:{"click":function($event){return _vm.onRemove({ accountIntegrationKey: integration.key })}}},on),[_vm._v("Remove")])]}}],null,true)},[_c('span',[_vm._v("Remove this integration")])]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":downloading},on:{"click":function($event){return _vm.downloadDataFromIntegration({
                integrationKey: integration.key
              })}}},[_vm._v(_vm._s('Download'))])],1):_vm._e()]}}],null,true)})],1)}),1):_vm._e(),_c('admin-account-integrations-dialog',{attrs:{"isOpen":_vm.showAddDialog,"companies":_vm.companies},on:{"close":function($event){_vm.showAddDialog = false}}}),_c('export-files-dialog',{attrs:{"is-open":_vm.showExportDialog,"processing":_vm.isExportProcessing,"error":_vm.exportDialogError,"email-to":_vm.login.profile.email},on:{"close-dialog":function($event){_vm.showExportDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }