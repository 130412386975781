<template>
  <v-list-tile @click="handleClick" class="tile">
    <v-list-tile-avatar size="30" class="tile-avatar">
      <v-icon size="18" color="white" class="tile-avatar--inactive">{{
        icon
      }}</v-icon>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title class="tile-title">{{ label }}</v-list-tile-title>
    </v-list-tile-content>
  </v-list-tile>
</template>

<script>
export default {
  name: 'MeetingNavDrawerListTileExternal',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '_blank'
    }
  },
  methods: {
    handleClick() {
      window.open(this.url, this.target);
    }
  }
};
</script>

<style scoped lang="scss">
.tile-avatar {
  color: #fff;
  &--active {
    background-color: #0f64a9;
  }
  &--inactive {
    background-color: #c4c4c4;
  }
}
.tile-title {
  font-size: 16px;
}
</style>
