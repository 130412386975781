<template>
  <v-layout row justify-center>
    <v-dialog
      scrollable
      :value="isOpen"
      @input="$emit('close')"
      max-width="950px"
    >
      <v-card>
        <v-card-title>
          <div class="title">{{ unitName }} - History log</div>
          <v-spacer />
          <v-btn small flat fab class="ma-0 mr-2">
            <v-icon @click="$emit('close')">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-layout row>
            <v-flex xs6>
              <v-text-field
                prepend-icon="search"
                label="Search by action or modified by"
                single-line
                hide-details
                clearable
                :value="searchQuery"
                @input="updateSearchQuery($event)"
              />
            </v-flex>
            <v-flex xs3 offset-xs3>
              <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="primary--text cursor-pointer">
                    Click here for more info
                    <v-icon color="primary" small>help</v-icon>
                  </span>
                </template>
                <v-card class="pa-3" width="400">
                  <h4 class="mb-3">Columns Description</h4>
                  <ul>
                    <li><b>Date</b>: The date the unit was modified</li>
                    <li>
                      <b>Action</b>: The action that was performed on the unit
                    </li>
                    <li><b>Modified By</b>: The user that modified the unit</li>
                    <li>
                      <b>Payload</b>: The values associated with the result of
                      the action
                    </li>
                  </ul>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="dataFormat"
            :loading="loading"
            :total-items="pagination.total"
            :rows-per-page-items="pagination.rowsPerPageItems"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.date }}</td>
                <td>{{ props.item.action }}</td>
                <td>{{ props.item.modifiedBy }}</td>
                <td>{{ props.item.payload }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click="$emit('close')">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  name: 'ViewUnitHistoryDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      default() {
        return false;
      }
    },
    unit: {
      type: Object
    },
    shortCode: {
      type: String,
      required: true
    }
  },
  watch: {
    isOpen: function(value) {
      // When the dialog is open, get the changelogs
      if (value) {
        this.fetchUnitChangelogs();
      }
    },
    searchQuery: function() {
      this.resetPagination();
      this.fetchUnitChangelogs();
    },
    pagination: function() {
      this.fetchUnitChangelogs();
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 5,
        rowsPerPageItems: [5, 10, 50, 100, 200],
        total: 0,
        descending: false,
        sortBy: 'date'
      },
      searchQuery: '',
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: true,
          width: '30%'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '25%'
        },
        { text: 'Modified By', sortable: false, value: 'modifiedBy' },
        { text: 'Payload', sortable: false, value: 'payload' }
      ],
      data: []
    };
  },
  computed: {
    unitName() {
      return this.unit ? this.unit.unitName : 'N/A';
    },

    dataFormat() {
      const format = _.map(this.data, e => {
        return {
          date: moment(e.createdAt).format('MMM D, YYYY h:mm A'),
          action: _.replace(e.action, /_/g, ' '),
          modifiedBy: _.isEmpty(e.meta.email) ? 'syncronization' : e.meta.email,
          payload: JSON.stringify(e.payload)
        };
      });

      return format;
    }
  },
  methods: {
    ...mapActions('meetings/units', ['getUnitChangelogs']),
    async fetchUnitChangelogs() {
      this.loading = true;
      try {
        const { data, total } = await this.getUnitChangelogs({
          shortCode: this.shortCode,
          unitId: this.unit.unitId,
          page: this.pagination.page,
          size: this.pagination.rowsPerPage,
          searchQuery: this.searchQuery,
          sort: this.pagination.descending ? 'desc' : 'asc'
        });

        this.data = data;
        this.pagination.total = total;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    updateSearchQuery: _.debounce(function(value) {
      this.searchQuery = value;
    }, 1000),
    resetPagination() {
      this.pagination.page = 1;
      this.pagination.rowsPerPage = 10;
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
