<template>
  <v-list
    class="elevation-1 ma-0 pa-0"
    :two-line="!displaySecondaryStat && !displayCustomGroups"
    :three-line="displaySecondaryStat || displayCustomGroups || notShowStats"
  >
    <v-list-tile avatar>
      <!-- Stat icon has tooltip on hover -->
      <div v-if="hasTooltip">
        <v-tooltip bottom>
          <v-list-tile-avatar slot="activator">
            <v-icon class="white--text blue">{{ iconName }}</v-icon>
          </v-list-tile-avatar>
          <span>{{ tooltipText }}</span>
        </v-tooltip>
      </div>
      <!-- Stat icon has no tooltip -->
      <div v-else>
        <v-list-tile-avatar slot="activator">
          <v-icon class="white--text blue">{{ iconName }}</v-icon>
        </v-list-tile-avatar>
      </div>

      <!-- Display number -->
      <v-list-tile-content>
        <v-list-tile-title>
          <v-progress-circular
            v-if="loadingStats"
            :size="20"
            indeterminate
            color="primary"
          />
          <span v-else-if="!loadingStats && getPrimaryNumber < 0" class="number"
            >?
          </span>
          <span v-else class="number">{{ getPrimaryNumber }} </span>

          <!-- If there is tertiary stat -->
          <span v-if="hasThird || displayInLineLabel">
            {{ getPrimaryLabel }}
            <a
              class="label blue--text"
              v-if="hasAction && displayInLineLabel"
              @click.prevent="emitAction"
            >
              ({{ actionText }})
            </a>
          </span>

          <!-- Warning that test voters exist -->
          <v-tooltip
            bottom
            v-if="
              getPrimaryNumber >= 0 && testVoters > 0 && showTestVoterWarning
            "
          >
            <template slot="activator">
              <v-icon class="pl-2" color="error">warning</v-icon>
            </template>
            <span>{{ testVoters }} units with test voters included</span>
          </v-tooltip>
        </v-list-tile-title>

        <v-list-tile-sub-title>
          <span v-if="!displayInLineLabel" class="label">
            <template v-if="hasOne">{{ getPrimaryLabel }}</template>
            <template v-else-if="!hasOne && getPrimaryNumber >= 0">{{
              getSecondaryStat
            }}</template>

            <a class="blue--text" v-if="hasAction" @click.prevent="emitAction">
              ({{ actionText }})
            </a>
          </span>
        </v-list-tile-sub-title>

        <v-list-tile-sub-title v-if="!hasOne && getPrimaryNumber >= 0">
          <span class="label">
            {{ getTertiaryStat }}
          </span>
        </v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    iconName: {
      type: String,
      default() {
        return '';
      }
    },
    tooltipText: {
      type: String,
      default() {
        return '';
      }
    },
    primaryStat: {
      type: String,
      default() {
        return '';
      }
    },
    secondaryStat: {
      type: String,
      default() {
        return '';
      }
    },
    tertiaryStat: {
      type: String,
      default() {
        return '';
      }
    },
    displaySecondaryStat: {
      type: Boolean,
      default: false
    },
    unitsStatNumber: {
      type: Number,
      default() {
        return 0;
      }
    },
    unitsLabel: {
      type: String,
      default() {
        return '';
      }
    },
    sharesStatNumber: {
      type: [Number, String],
      default() {
        return 0;
      }
    },
    sharesLabel: {
      type: String,
      default() {
        return '';
      }
    },
    groupsStatNumber: {
      type: [Number, String],
      default() {
        return 0;
      }
    },
    groupsLabel: {
      type: String,
      default() {
        return '';
      }
    },
    displayCustomGroups: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default() {
        return 'primary';
      }
    },
    hasAction: {
      type: Boolean,
      default: false
    },
    notShowStats: {
      type: Boolean,
      default: false
    },
    actionText: {
      type: String,
      default() {
        return null;
      }
    },
    displaySharesAsPercentages: {
      type: Boolean,
      default() {
        return false;
      }
    },
    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default() {
        return '0';
      }
    },
    displayInLineLabel: {
      type: Boolean,
      default() {
        return false;
      }
    },
    testVoters: {
      type: Number,
      default() {
        return 0;
      }
    },
    loadingStats: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showTestVoterWarning: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasTooltip() {
      return this.tooltipText !== '';
    },
    hasThird() {
      return this.displaySecondaryStat && this.displayCustomGroups;
    },
    hasOne() {
      return !this.displaySecondaryStat && !this.displayCustomGroups;
    },

    getPrimaryNumber() {
      switch (this.primaryStat) {
        case 'Units':
          return this.unitsStatNumber;
        case 'Shares':
          return this.formatShares();
        case 'Groups':
          return this.groupsStatNumber;
        default:
          return this.unitsStatNumber;
      }
    },
    getPrimaryLabel() {
      const switchValue = this.hasOne ? this.secondaryStat : this.primaryStat;
      const primaryLabel = switchValue || 'Units';

      switch (primaryLabel) {
        case 'Units':
          return this.unitsLabel;
        case 'Shares':
          return this.sharesLabel;
        case 'Groups':
          return this.groupsLabel;
        default:
          return this.unitsLabel;
      }
    },
    getSecondaryStat() {
      const switchValue =
        this.hasOne || this.hasThird ? this.secondaryStat : this.primaryStat;
      switch (switchValue) {
        case 'Units':
          return this.hasThird
            ? `(${this.unitsStatNumber.toLocaleString()} ${this.unitsLabel})`
            : this.unitsLabel.toLocaleString();
        case 'Shares':
          return this.hasThird
            ? `(${this.formatShares()} ${this.sharesLabel})`
            : this.sharesLabel.toLocaleString();
        case 'Groups':
          return this.hasThird
            ? `(${this.groupsStatNumber.toLocaleString()} ${this.groupsLabel})`
            : this.groupsLabel.toLocaleString();
        default:
          return this.hasThird
            ? `(${this.unitsStatNumber.toLocaleString()} ${this.unitsLabel})`
            : this.unitsLabel.toLocaleString();
      }
    },
    getTertiaryStat() {
      const switchValue = this.hasThird
        ? this.tertiaryStat
        : this.secondaryStat;
      switch (switchValue) {
        case 'Units':
          return `(${this.unitsStatNumber.toLocaleString()} ${
            this.unitsLabel
          })`;
        case 'Shares':
          return `(${this.formatShares()} ${this.sharesLabel})`;
        case 'Groups':
          return `(${this.groupsStatNumber.toLocaleString()} ${
            this.groupsLabel
          })`;
        default:
          return `(${this.unitsStatNumber.toLocaleString()} ${
            this.unitsLabel
          })`;
      }
    }
  },
  methods: {
    emitAction() {
      this.$emit('run-action');
    },
    formatShares() {
      return this.displaySharesAsPercentages
        ? parseFloat(Number(this.sharesStatNumber) * 100).toFixed(
            this.displayRoundedResults ? this.roundedResultsPrecision : 0
          ) + '%'
        : this.formatSharesRound(this.sharesStatNumber);
    },
    formatSharesRound(value) {
      return this.displayRoundedResults && value
        ? parseFloat(Number(value)).toFixed(
            parseInt(this.roundedResultsPrecision)
          )
        : value;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.number {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  font-size: 27px;
}
.label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
}
.download-label {
  font-size: 13px;
}
.groups-container {
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 2px;
  margin: auto;
}
.groups-number {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  font-size: 15px;
}
.groups-label {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
