<template>
  <!-- The Route View -->
  <v-content>
    <!-- Alert status for service agreement -->
    <!--  <service-agreement-alert
      v-if="isAdmin && currentMeetingAccount"
      :current-meeting-account="currentMeetingAccount"
    /> -->

    <!-- Nested back button visible where $route.meta.nestedPage property exists -->
    <meeting-nav-nested-tab v-if="nestedPage" :nested-page="nestedPage" />

    <!-- Show header if navLabel is truthy -->
    <v-layout class="justify-space-between align-center">
      <h5
        v-if="this.$route.meta.navLabel"
        class="headline font-weight-bold pl-4 pt-4"
      >
        {{ this.$route.meta.navLabel }}
      </h5>

      <div>
        <v-menu offset-y open-on-hover v-if="currentIntegrationStatus">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="currentIntegrationStatus.color"
              :text-color="currentIntegrationStatus.textColor"
              class="mt-4 mr-4"
            >
              <span>{{ currentIntegrationStatus.text }}</span>
            </v-chip>
          </template>
          <v-card class="pa-3" width="300">
            <div v-html="currentIntegrationStatus.tooltip" />
          </v-card>
        </v-menu>
      </div>
    </v-layout>

    <v-divider v-if="this.$route.meta.navLabel" class="gq-divider mx-4" />

    <v-alert
      :value="this.$route.meta.workInProgress"
      color="warning"
      icon="construction"
      outline
      class="ma-3"
    >
      <span>This page is currently a work in progress</span>
      <span v-if="this.$route.meta.adminOnly"
        >, and is only visible to admins</span
      >
    </v-alert>

    <transition name="slide-fade" mode="out-in">
      <div class="load-center" v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <error-status
        v-else-if="error"
        :code="error.status"
        :message="error.statusText"
        @reload="init"
      />
      <router-view
        v-else
        :current-meeting="currentMeeting"
        :account="currentMeetingAccount"
      />
    </transition>
  </v-content>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import ErrorStatus from '@/components/ErrorStatus';
import MeetingNavNestedTab from './MeetingNavNestedTab';
import ServiceAgreementAlert from '@/components/ServiceAgreementAlert';

export default {
  name: 'MeetingMain',
  components: {
    ErrorStatus,
    MeetingNavNestedTab,
    ServiceAgreementAlert
  },
  computed: {
    ...mapGetters(['isAdmin', ['login']]),
    ...mapGetters('meetings', ['meetingByShortCode']),
    ...mapGetters('accounts', ['accountList']),
    currentMeeting() {
      return this.meetingByShortCode(this.$route.params.shortcode);
    },
    currentMeetingAccount() {
      return _.get(this.currentMeeting, 'accountKey')
        ? this.accountList[this.currentMeeting.accountKey]
        : null;
    },
    nestedPage() {
      // If a route.meta nestedPage property exists, return the value
      if (this.$route.meta.hasOwnProperty('nestedPage')) {
        return this.$route.meta.nestedPage;
      }
    },
    hasIntegration() {
      return (
        this.currentMeetingAccount.integrations &&
        this.currentMeetingAccount.integrations.length > 0
      );
    },
    hasAgentIntegrationAvailable() {
      return _.some(
        this.currentMeetingAccount.agents,
        agent => agent.integrationAvailable
      );
    },
    isSyncEnabled() {
      return _.get(
        this.currentMeeting,
        'options.integration.enableVoterSync',
        false
      );
    },
    isSyncExpired() {
      const syncEndDate = _.get(
        this.currentMeeting,
        'options.integration.syncEndDate'
      );

      return this.isSyncEnabled && moment(syncEndDate).isBefore(moment());
    },
    currentIntegrationStatus() {
      const integrations = _.get(
        this.currentMeetingAccount,
        'integrations',
        []
      );
      const agents = _.get(this.currentMeetingAccount, 'agents', []);

      const hasAgentIntegrationAvailable = _.some(
        agents,
        agent => agent.integrationAvailable
      );

      const meetingSettingsUrl = `/meetings/${this.$route.params.shortcode}/settings?view=IntegrationInput`;

      // if the account has integrations and the meeting has integration sync expired
      if (integrations && integrations.length > 0 && this.isSyncExpired) {
        return {
          text: 'Integration: Stopped',
          tooltip: `The integration is no longer active as the synchronization window is closed. Click <a href="${meetingSettingsUrl}" target="_blank">here</a> to go to the Meeting Integration Settings for details.`,
          color: 'gray',
          textColor: 'black'
        };
      }

      // if the account has integrations and the meeting has integration sync active
      if (integrations && integrations.length > 0 && this.isSyncEnabled) {
        return {
          text: 'Integration: Active',
          tooltip: `Integration sync is active. Click <a href="${meetingSettingsUrl}" target="_blank">here</a> to view the Integration settings.`,
          color: 'green',
          textColor: 'white'
        };
      }

      // if the account has integrations
      if (integrations && integrations.length > 0) {
        return {
          text: 'Integration: Available - Not Active',
          tooltip: `An integration is available for this Account, but is not currently active for this campaign. Click <a href="${meetingSettingsUrl}" target="_blank">here</a> to go to the Integration settings for more details.`,
          color: 'gray',
          textColor: 'black'
        };
      }

      // if there are account agents with integration available
      if (hasAgentIntegrationAvailable) {
        return {
          text: 'Integration: Available (Agent)',
          tooltip: `An integration may be available for this campaign. The Agent for the Account has been marked as having an integration available. Please contact the Dev Team for more information.`,
          color: 'orange',
          textColor: 'black'
        };
      }

      return null;
    }
  },
  data: () => ({
    loading: true,
    drawer: null,
    error: null
  }),
  created() {
    // Subscribe to the pusher channel for this meeting's Dashboard events
    this.initPusher({ shortCode: this.$route.params.shortcode });
    this.init();
  },
  methods: {
    ...mapActions(['initPusher', 'destroyPusher']),
    ...mapActions('meetings', ['getMeeting']),
    async init() {
      this.loading = true;
      try {
        await this.getMeeting({
          shortCode: this.$route.params.shortcode
        });
      } catch (err) {
        if (err.response) {
          this.error = err.response;
        } else if (err.request) {
          this.error = { status: 503, statusText: 'Service unavailable' };
        } else {
          console.log(err);
          this.error = { status: 520, statusText: 'Unknown Error' };
        }
      } finally {
        this.loading = false;
      }
    }
  },
  destroyed() {
    this.destroyPusher();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/common.scss';
.load-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.gq-divider {
  border-top: 2px solid #3874cb !important;
  margin: 8px 0 8px 0;
}
</style>
