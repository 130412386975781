'use strict';

/**
 * Determine if proxy question is eligible based on restrictions
 * Determine if question meets eligibility requirements for check-voter-subgroup middleware in the gq-api project
 *
 * @param  {Object} question              - the meeting business question
 * @param  {Object} currentUnitCustomData - the current unit custom data
 * @return {Boolean}                       - whether the question is eligible
 */
export function isQuestionEligible({ question, currentUnitCustomData }) {
  // If question has no restrictions, it's always eligible
  if (!question.restrictions || question.restrictions.length === 0) {
    return true;
  }

  // Exception: if any restriction has the "owner_occupied" key, automatically eligible
  const hasOwnerOccupiedRestriction = question.restrictions.some(
    restriction => 'owner_occupied' in restriction
  );

  if (hasOwnerOccupiedRestriction) {
    return true;
  }

  // Check if any restriction matches the current unit's custom data
  // A question is eligible if ANY of its restrictions match
  return question.restrictions.some(restriction => {
    // For each restriction object, check if ANY key-value pair matches in currentUnitCustomData
    return Object.entries(restriction).some(
      ([key, value]) => currentUnitCustomData[key] === value
    );
  });
}
