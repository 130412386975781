<template>
  <v-container grid-list-xl>
    <div v-if="currentIntegration">
      <div class="title grey--text text--darken-4 mb-1">
        Integration & Sync Settings
      </div>
      <div class="body-1 grey--text text--darken-2 mb-4 mt-4">
        <b>Integration</b>: {{ currentIntegration.name }} - Client ID:
        {{ currentIntegration.assocCode }} (<a
          :href="accountIntegrationsUrl"
          target="_blank"
          >See Account</a
        >)
      </div>

      <v-layout>
        <div class="pr-3">
          <v-checkbox
            v-model="enableVoterSync"
            @change="onChange('options.integration.enableVoterSync', $event)"
          />
        </div>
        <div>
          <div class="subheading">
            Enable Automatic Unit Voter Synchronization
          </div>
          <div class="grey--text text--darken-2">
            Synchronize voters in a unit <b>once a day</b> from the following
            start and end dates.
          </div>
        </div>
      </v-layout>

      <div v-if="enableVoterSync">
        <date-time-pickers
          label="Sync Start Date"
          :timezone="timezone"
          :iso-date="startDate"
          :show-clear="false"
          @update="onChange('options.integration.syncStartDate', $event)"
        />

        <date-time-pickers
          label="Sync End Date"
          :timezone="timezone"
          :iso-date="endDate"
          :show-clear="false"
          :max="maxDate"
          @update="onChange('options.integration.syncEndDate', $event)"
        />

        <div v-if="isFloridaRegion" class="my-3">
          <div class="subheading">
            Automatic Consent Emails
          </div>
          <v-layout class="consent-container">
            <v-flex xs6>
              <v-select
                :value="consentEmailTemplate"
                :items="resendableNotices"
                item-text="subject"
                item-value="key"
                label="Consent email template"
                placeholder="Choose an email template"
                @change="
                  onChange('options.integration.consentEmailTemplate', $event)
                "
              />
            </v-flex>
            <v-flex xs6>
              <v-checkbox
                v-model="enableAutomaticConsent"
                :label="automaticConsentText"
                :disabled="!consentEmailTemplate"
                @change="
                  onChange('options.integration.enableAutomaticConsent', $event)
                "
              />
            </v-flex>
          </v-layout>
        </div>

        <div class="my-3">
          <div class="subheading">
            Proxy/Ballot & Ownership Change Notifications
          </div>
          <v-layout class="consent-container">
            <v-flex xs6>
              <v-text-field
                clearable
                label="Send email to"
                placeholder="cs.audit@getquorum.com"
                :value="proxyBallotReviewEmailTo"
                @input="
                  onChange(
                    'options.integration.proxyBallotReviewEmailTo',
                    $event
                  )
                "
              />
            </v-flex>
            <v-flex xs6>
              <v-checkbox
                v-model="enableProxyBallotReviewReport"
                label="Send a summary of proxies/ballots that need to be reviewed for revocation when email used to submit the vote is deleted from the unit"
                @change="
                  onChange(
                    'options.integration.enableProxyBallotReviewReport',
                    $event
                  )
                "
              />
            </v-flex>
          </v-layout>
        </div>

        <v-divider class="my-4" />
        <v-layout row wrap>
          <v-flex xs12>
            <div class="subheading">
              Sync logs
            </div>
          </v-flex>
          <v-flex xs12>
            <meeting-logs-table
              :logs="meetingLogs"
              :meeting-timezone="meetingTimezone"
              :loading="loading"
              :short-code="shortCode"
            />
          </v-flex>
        </v-layout>
      </div>
    </div>
    <div v-else><div>No Integration</div></div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import DateTimePickers from '@/components/DateTimePicker';
import MeetingLogsTable from '@/components/MeetingLogsTable.vue';
import moment from 'moment';

export default {
  name: 'MeetingSettingsIntegrationInput',
  components: {
    DateTimePickers,
    MeetingLogsTable
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    currentAccount: {
      type: Object,
      required: true
    },
    meetingTimezone: {
      type: String,
      default: ''
    },
    meetingDate: {
      type: String,
      required: true
    },
    shortCode: {
      type: String,
      required: true
    }
  },
  watch: {
    options: {
      handler() {
        this.initState();
      },
      deep: true
    }
  },
  async mounted() {
    this.initState();

    if (this.enableVoterSync) {
      await this.getMeetingLogs();

      // Get list of Notices,
      // so users can choose consent template
      if (this.resendableNotices.length <= 0) {
        await this.getMeetingNotices({
          shortCode: this.shortCode,
          includeStats: false
        });
      }
    }
  },
  data() {
    return {
      enableVoterSync: false,
      enableAutomaticConsent: false,
      enableProxyBallotReviewReport: true,
      proxyBallotReviewEmailTo: 'cs.audit@getquorum.com',
      timezone: this.meetingTimezone,
      startDate: '2024-12-05T00:00:00.000Z',
      endDate: '2024-12-05T00:00:00.000Z',
      meetingLogs: [],
      loading: false,
      consentEmailTemplate: null
    };
  },
  computed: {
    ...mapGetters('meetings/notices', ['meetingNoticeListDataSorted']),
    currentIntegration() {
      return _.get(this.currentAccount, 'integrations[0]', null);
    },
    isFloridaRegion() {
      return this.currentAccount.region === 'FL';
    },
    accountIntegrationsUrl() {
      return `/admin/accounts/${this.currentAccount.key}/integrations`;
    },
    resendableNotices() {
      return this.meetingNoticeListDataSorted.filter(
        notice => notice.isResendable && notice.status !== 'draft'
      );
    },
    maxDate() {
      return moment(this.meetingDate)
        .tz(this.meetingTimezone)
        .format('YYYY-MM-DD');
    },
    automaticConsentText() {
      return `Enable sending consent email automatically on ownership changes. Emails will only be sent prior to the consent deadline.`;
    }
  },
  methods: {
    ...mapActions('meetings/notices', ['getMeetingNotices']),
    ...mapActions('meetings', ['getMeetingLogsAction']),
    initState() {
      this.enableVoterSync = _.get(
        this.options,
        'integration.enableVoterSync',
        false
      );

      this.enableAutomaticConsent = _.get(
        this.options,
        'integration.enableAutomaticConsent',
        false
      );

      this.consentEmailTemplate = _.get(
        this.options,
        'integration.consentEmailTemplate',
        null
      );

      this.startDate = _.get(
        this.options,
        'integration.syncStartDate',
        moment().toISOString()
      );

      this.endDate = _.get(
        this.options,
        'integration.syncEndDate',
        moment(this.meetingDate).toISOString()
      );

      this.proxyBallotReviewEmailTo = _.get(
        this.options,
        'integration.proxyBallotReviewEmailTo',
        'cs.audit@getquorum.com'
      );

      this.enableProxyBallotReviewReport = _.get(
        this.options,
        'integration.enableProxyBallotReviewReport',
        true
      );
    },
    onChange(key, value) {
      if (key === 'options.integration.consentEmailTemplate') {
        this.consentEmailTemplate = value;
      }

      if (key === 'options.integration.proxyBallotReviewEmailTo' && !value) {
        this.enableProxyBallotReviewReport = false;
        this.$emit('update', {
          key: 'options.integration.enableProxyBallotReviewReport',
          value: false
        });
      }

      this.$emit('update', { key, value });
    },

    async getMeetingLogs() {
      this.loading = true;
      try {
        const data = await this.getMeetingLogsAction({
          shortCode: this.shortCode
        });

        this.meetingLogs = data;
      } catch (err) {
        console.error('Error: getMeetingLogsAction action', err);
        throw err;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.consent-container {
  display: flex;
  align-items: center;
}
</style>
