<template>
  <v-dialog :value="isOpen" max-width="400px" persistent>
    <v-card>
      <v-card-title>
        <div class="headline">
          Add New Integration
        </div>
      </v-card-title>
      <div class="pa-3">
        <v-flex xs12 md12>
          <v-form ref="form">
            <v-select
              label="Choose Integration"
              :items="options"
              v-model="form.integration"
              item-text="name"
              return-object
              :rules="[v => !!v || 'Integration is required']"
              required
            />
            <v-select
              label="Integration company"
              :items="integrationCompanies"
              v-model="form.company"
              item-text="name"
              item-value="key"
              :rules="[v => !!v || 'Company is required']"
              required
            />
            <v-text-field
              label="Association code"
              v-model="form.code"
              :rules="[v => !!v || 'Code is required']"
              required
            />
          </v-form>
        </v-flex>
      </div>
      <v-card-actions>
        <v-btn
          color="primary"
          flat
          @click.native="emitCloseDialog"
          :disabled="loading"
          >Cancel</v-btn
        >
        <v-spacer />
        <v-btn color="primary" @click.native="onAdd" :loading="loading">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { getIntegrations } from '@/api/integrations';

export default {
  name: 'AdminAccountIntegrationsDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    companies: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [],
      loading: false,
      accountKey: this.$route.params.accountKey,
      form: {
        integration: {},
        code: '',
        company: ''
      }
    };
  },
  watch: {
    isOpen() {
      if (!this.isOpen) {
        this.resetForm();
      }
    }
  },
  created() {
    this.init();
  },
  computed: {
    integrationCompanies() {
      const integrationSelected = this.form.integration;
      const integrationName = integrationSelected.name;
      return this.companies[integrationName] || [];
    }
  },
  methods: {
    ...mapActions('accounts', ['upsertIntegration']),
    async init() {
      try {
        const response = await getIntegrations();
        this.options = response.data;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    emitCloseDialog() {
      this.$emit('close');
      this.resetForm();
    },
    async onAdd() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.upsertIntegration({
            accountKey: this.accountKey,
            integrationKey: this.form.integration.key,
            code: this.form.code,
            company: this.form.company
          });
          this.emitCloseDialog();
        } catch (err) {
          this.$events.$emit('showErrorDialog', err.response);
        } finally {
          this.loading = false;
        }
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.form = {
        integration: {},
        code: '',
        company: ''
      };
    }
  }
};
</script>
