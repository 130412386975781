<template>
  <div>
    <toolbar
      :editor="editor"
      :hide-merge-menu="hideMergeMenu"
      :custom-fields="customFields"
      :is-uploading="isUploading"
      :custom-label="customLabel"
      :show-custom-fields="showCustomFields"
      :show-button-options="showButtonOptions"
    ></toolbar>
    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap';
import { uploadPublicImages } from '@/api/files';
import Toolbar from './Toolbar';
import AlignText from './extensions/align-text';
import Image from './extensions/image';

import {
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Underline,
  History,
  HardBreak,
  Table,
  TableRow,
  TableCell,
  TableHeader,
  HorizontalRule
} from 'tiptap-extensions';

export default {
  name: 'EmailEditor',
  components: {
    EditorContent,
    Toolbar
  },
  props: {
    body: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: null
    },
    customFields: {
      type: Array,
      default() {
        return [];
      }
    },
    hideMergeMenu: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    showCustomFields: {
      type: Boolean,
      default: false
    },
    customLabel: {
      type: String,
      default: ''
    },
    showButtonOptions: { type: Boolean, default: false },
    allowBase64: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    editable(newValue) {
      if (this.editor) {
        this.editor.setOptions({ editable: newValue });
      }
    }
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new BulletList(),
          new Heading({ levels: [1, 2, 3, 4] }),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new AlignText(),
          new HardBreak(),
          new Image(null, null, this.upload),
          new Table(),
          new TableRow(),
          new TableCell(),
          new TableHeader(),
          new HorizontalRule()
        ],
        content: this.body,
        onUpdate: ({ getHTML }) => {
          const state = getHTML();

          if (!this.allowBase64 && this.containsBase64Image(state)) {
            alert(
              'Copy and pasting images is not allowed. Please drag and drop any images into the text editor.'
            );
            return;
          } else {
            this.$emit('update', state);
          }
        },
        editable: this.editable
      }),
      isUploading: false
    };
  },
  methods: {
    async upload(file) {
      try {
        this.$set(this, 'isUploading', true);
        this.isUploading = true;
        const urls = await uploadPublicImages([file]);
        return urls[0];
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.$set(this, 'isUploading', false);
        this.isUploading = false;
      }
    },

    containsBase64Image(str) {
      var regex = /data:image\/(png|jpeg|jpg);base64,/;

      return regex.test(str);
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style>
.editor__content .ProseMirror {
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  white-space: pre-wrap;
  border: none;
  border: 1px solid #ccc;
  outline: none;
  resize: vertical;
  padding: 15px;
  font-size: 13px;
}
.editor__content img {
  height: 100%;
  max-width: 100%;
}

.editor__content table td,
.editor__content table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
</style>
