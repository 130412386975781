<template>
  <v-container fluid class="mt-0 pt-0">
    <!-- Displayed ONLY if you are an admin and combined vote tally data exists -->
    <div v-if="canSeeLiveVoteData && voteTallyCombinedInitV2.length > 0">
      <v-layout>
        <div class="mt-3">
          Click on the ELIGIBLE | INELIGIBLE button to toggle candidate
          eligibility
          <v-icon
            @click="showCandidateEligibilityDialog = true"
            color="primary"
            small
            >info</v-icon
          >
        </div>
        <v-spacer />
        <div>
          <span class="caption">
            <span v-if="isTallyLoading">Loading...</span>
            <span v-else>Updated {{ refreshTallyStatus }}</span>
          </span>
          <v-btn
            flat
            icon
            :loading="isTallyLoading"
            color="secondary"
            @click.native="refreshTallyV2"
          >
            <v-icon>cached</v-icon>
          </v-btn>
          <v-btn class="primary" @click.native="viewLiveVoteAnswers" flat>
            VIEW VOTES
          </v-btn>
        </div>
      </v-layout>

      <vote-tally-combined-v2
        :questions="voteTallyCombinedInitV2"
        :meetingTimezone="currentMeeting.meetingTimezone"
        :display-rounded-results="currentMeeting.options.roundedResults"
        :rounded-results-precision="
          currentMeeting.options.roundedResultsPrecision
        "
        @add-paper-option="addPaperOptionV2"
        @cancel-paper-option="cancelPaperOptionV2"
        @delete-paper-option="deletePaperOptionV2"
        @save-paper-changes="savePaperChangesV2"
        @cancel-paper-changes="cancelPaperChangesV2"
        @enable-save-paper-changes="enableSavePaperChangesV2"
        @view-write-in-vote="viewWriteInVote"
        @toggle-exclude-vote="toggleExcludeVote"
        @toggle-winner="toggleWinnerVote"
      />

      <!-- Dialogs -->
      <write-in-vote-dialog
        v-model="showWriteInVoteDialog"
        :proxy-list="writeInProxyList"
        @revoke-proxy="revokeProxy"
        @close="showWriteInVoteDialog = false"
      />

      <candidate-eligibility-toggle-dialog
        :is-open="showCandidateEligibilityDialog"
        @close="showCandidateEligibilityDialog = false"
      />
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import checkScope from '@/lib/check-user-scopes';
import VoteTallyCombinedV2 from '@/components/VoteTallyCombinedV2';

import CandidateEligibilityToggleDialog from '@/components/dialogs/CandidateEligibilityToggleDialog';
import WriteInVoteDialog from '@/components/dialogs/WriteInVoteDialog';

export default {
  name: 'MeetingTallyView',
  components: {
    VoteTallyCombinedV2,
    WriteInVoteDialog,
    CandidateEligibilityToggleDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['login', 'isAdmin', 'scopes']),
    ...mapGetters('meetings/liveVotes', ['meetingRTVCombinedVoteV2ListData']),
    ...mapGetters('meetings/proxies', ['meetingWriteInProxyListData']),
    canSeeLiveVoteData() {
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.live-votes.read')
      );
    },
    voteTallyCombinedInitV2() {
      return this.prepareDataV2();
    },
    writeInProxyList() {
      return this.meetingWriteInProxyListData;
    }
  },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      isTallyLoading: false,
      lastTallyRefresh: new Date(),
      refreshTallyStatus: moment().fromNow(),
      showCandidateEligibilityDialog: false,
      showWriteInVoteDialog: false
    };
  },
  mounted() {
    // Retrieve the latest combined advanced + live vote tally
    if (this.canSeeLiveVoteData) {
      this.getMeetingRTVCombinedVoteV2Data({ shortCode: this.shortCode });

      let self = this;
      // Update the data age clock every minute
      setInterval(function() {
        self.updateRefreshStatus();
      }, 60000);
    }
  },
  methods: {
    ...mapActions('meetings/liveVotes', [
      'getMeetingRTVCombinedVoteV2Data',
      'toggleVoteExclusion',
      'toggleWinner'
    ]),
    ...mapActions('meetings/proxies', [
      'deleteMeetingPaperProxy',
      'saveMeetingPaperProxies',
      'listMeetingProxyWriteIns',
      'revokeMeetingWriteInProxy'
    ]),
    updateRefreshStatus() {
      this.refreshTallyStatus = moment(this.lastTallyRefresh).fromNow();
    },
    viewLiveVoteAnswers() {
      this.$router.push({
        name: 'meetingTallyData',
        params: {
          shortcode: this.$route.params.shortcode
        }
      });
    },
    // ----- V2 related - Start ----- //
    async refreshTallyV2() {
      try {
        this.isTallyLoading = true;
        await this.getMeetingRTVCombinedVoteV2Data({
          shortCode: this.shortCode
        });

        // Set the latest refresh and update the refresh status
        this.lastTallyRefresh = new Date();
        this.updateRefreshStatus();
      } catch (err) {
        throw err;
      } finally {
        this.isTallyLoading = false;
      }
    },
    prepareDataV2() {
      // 1) Calculate the total votes from what has been stored
      // 2) Retain the saved paper vote tally vs what's been edited/unsaved
      // 3) Edits won't update the total votes column (live+adv+paper votes)
      // 4) Handle special paper vote values (blanks are zeros)
      return _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        // Keep a flag for each question that could potentially be edited
        // Determines whether the save button is activated for the question
        if (question.canSave === undefined) {
          question.canSave = false;
        }

        _.map(question.tally, answer => {
          // Populate the editable paper vote text field
          // For vote values of 0, format to empty
          if (answer.numVotesPaperEdit === undefined) {
            answer.numVotesPaperEdit =
              answer.numVotesPaper === 0 ? '' : answer.numVotesPaper;
          }

          // Excluded votes are shifted to the bottom of the tables
          if (answer.isExcluded) {
            answer.numVotesTotal = '-';
            return answer;
          }

          // Keep track of the total number of votes from stored values
          answer.numVotesTotal =
            answer.numVotes +
            answer.numVotesAdv +
            (!answer.numVotesPaper ? 0 : parseFloat(answer.numVotesPaper));

          return answer;
        });
        return question;
      });
    },
    addPaperOptionV2(questionId) {
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        if (question.id === questionId) {
          let tallyWithNewLine = question.tally;
          const blankOption = {
            newOptionKey: `new-${tallyWithNewLine.length}`,
            vote: '',
            numVotesPaperEdit: null
          };
          tallyWithNewLine.push(blankOption);
          question.tally = tallyWithNewLine;
        }
        return question;
      });
    },
    cancelPaperOptionV2({ questionId, optionKey }) {
      // Find and remove the target option passed
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        if (question.id === questionId) {
          // Clone the question tally so we can operate on it
          const filterTally = _.cloneDeep(question.tally);
          _.remove(filterTally, {
            newOptionKey: optionKey
          });
          question.tally = filterTally;
          this.$events.$emit('toastEvent', 'Option removed');
        }
        return question;
      });
    },
    // Delete a saved paper option - applied on the advance question reference
    async deletePaperOptionV2({ questionKey, vote, liveQuestionKey }) {
      try {
        await this.deleteMeetingPaperProxy({
          shortCode: this.shortCode,
          questionKey,
          vote,
          liveQuestionKey
        });
        this.$events.$emit('toastEvent', 'Paper proxy removed');

        this.refreshTallyV2();
      } catch {
        this.$events.$emit('toastEvent', 'Could not remove paper vote entry');
      }
    },
    async savePaperChangesV2(questionId) {
      const saveQuestion = _.find(this.meetingRTVCombinedVoteV2ListData, {
        id: questionId
      });
      // Save the changes - applied on the advance question reference
      try {
        await this.saveMeetingPaperProxies({
          shortCode: this.shortCode,
          questionKey: saveQuestion.question_key,
          paperProxies: saveQuestion.tally,
          liveQuestionKey: saveQuestion.live_question_key
        });
        this.$events.$emit('toastEvent', 'Changes saved');
        this.refreshTallyV2();
      } catch {
        this.$events.$emit(
          'toastEvent',
          'Changes could not be save, invalid input found'
        );
      }
    },
    async cancelPaperChangesV2(questionId) {
      // Find and reset question
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        // Find the target question
        if (question.id === questionId) {
          // Reset and remove unsaved new line items
          question.tally = _.filter(question.tally, 'answerId');

          // Reset the edit vote total
          _.map(question.tally, vote => {
            vote.numVotesPaperEdit =
              vote.numVotesPaper === 0 ? '' : vote.numVotesPaper;
            return vote;
          });
          question.canSave = false;
        }
        return question;
      });
      this.$events.$emit('toastEvent', 'Changes have been reset');
    },
    async enableSavePaperChangesV2(questionId) {
      // Enable the save button for the target question
      _.map(this.meetingRTVCombinedVoteV2ListData, question => {
        // Find the target question
        if (question.id === questionId) {
          question.canSave = true;
        }
        return question;
      });
    },
    async toggleExcludeVote({ questionKey, exclusion, liveQuestionKey }) {
      try {
        await this.toggleVoteExclusion({
          shortCode: this.shortCode,
          questionKey,
          liveQuestionKey,
          exclusion
        });

        // Refresh the combined vote tally after successfull proxy revokes
        this.refreshTallyV2();
      } catch (err) {
        const message =
          err.response.data || 'Could not toggle exclusion, please try again';
        this.$events.$emit('toastEvent', message);
      }
    },
    async viewWriteInVote({ questionKey, vote }) {
      try {
        this.listMeetingProxyWriteIns({
          shortCode: this.shortCode,
          questionKey,
          vote
        });

        this.showWriteInVoteDialog = true;
      } catch {
        this.$events.$emit('toastEvent', 'Could not retrieve write-in proxies');
      }
    },
    async revokeProxy({ proxyKey }) {
      try {
        await this.revokeMeetingWriteInProxy({
          shortCode: this.shortCode,
          proxyKey
        });

        this.$events.$emit('toastEvent', 'Proxy Revoked');

        // Refresh the combined vote tally after successfull proxy revokes
        this.refreshTallyV2();
      } catch (err) {
        this.$events.$emit(
          'toastEvent',
          'Could not revoke proxy, please try again'
        );
      }
    },
    async toggleWinnerVote({ questionKey, liveQuestionKey, winner }) {
      try {
        await this.toggleWinner({
          shortCode: this.shortCode,
          questionKey,
          winner,
          liveQuestionKey
        });

        // Refresh the combined vote tally after successfull proxy revokes
        this.refreshTallyV2();

        // Refresh Advance voting results
        this.getMeetingBusiness({ shortCode: this.shortCode });
      } catch (err) {
        const message = err.response.data || 'Could not toggle winner';
        this.$events.$emit('toastEvent', message);
      }
    }
  }
};
</script>

<style scoped></style>
